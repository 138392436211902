import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

// plugin
import { AgmCoreModule } from '@agm/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { DataTablesModule } from 'angular-datatables';
// import { CKEditorModule } from 'ng2-ckeditor';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxSpinnerModule } from 'ngx-spinner';
import { RatingModule } from 'ngx-bootstrap/rating';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';


// Directive
import { GooglePlacesDirective } from '../directives/google-places.directive';

// service
import { BnDatatableModule } from '../../common/service/bn-datatable/bn-datatable.module';

// pipe
import { PipeModule } from '../../common/pipes/pipe.module';

// Component
import { FilterTypeAheadComponent } from '../components//filter-type-ahead/filter-type-ahead.component';
import { DetailsFormComponent } from '../components/details-form/details-form.component';
import { GrapesJSComponent } from '../components/grapes-js/grapes-js.component';
import { HeaderComponent } from '../components/header/header.component';
import { OrderDetailComponent } from '../components/order-detail/order-detail.component';
import { PasswordComponent } from '../components/password/password.component';
import { ResponsiveDataTableComponent } from '../components/responsive-data-table/responsive-data-table.component';
import { SidebarComponent } from '../components/sidebar/sidebar.component';
import { SubHeaderComponent } from '../components/sub-header/sub-header.component';
import { AssignDriverComponent } from '../components/assign-driver/assign-driver.component';
import { CropperModalComponent } from '../modals/cropper-modal/cropper-modal.component';



@NgModule({
  imports: [
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAFgM81Qz-SwfTzUsr4F51AgDj0HdN88CQ',
    }),
    NgxSkeletonLoaderModule,
    NgbModule,
    TypeaheadModule.forRoot(),
    TooltipModule.forRoot(),
    NgxFileDropModule,
    MatButtonModule, MatInputModule, MatFormFieldModule, MatSelectModule,
    RouterModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    RatingModule.forRoot(),
    HttpClientModule,
    FormsModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    NgSelectModule,
    TabsModule.forRoot(),
    MalihuScrollbarModule.forRoot(),
    BnDatatableModule,
    LoadingBarHttpClientModule,
    PaginationModule.forRoot(),
    ImageCropperModule,
    MatTooltipModule,
    DataTablesModule,
    // CKEditorModule,
     PipeModule,
    NgxIntlTelInputModule
  ],
  declarations: [
    FilterTypeAheadComponent,
    PasswordComponent,
    SubHeaderComponent,
    ResponsiveDataTableComponent,
    GrapesJSComponent,
    HeaderComponent,
    SidebarComponent,
    DetailsFormComponent,
    OrderDetailComponent,
    GooglePlacesDirective,
    AssignDriverComponent,
    CropperModalComponent,
  ],
  providers: [],
  schemas: [NO_ERRORS_SCHEMA],
  exports: [
    /* Modules */
    NgbModule,
    NgxFileDropModule,
    MatButtonModule, MatInputModule, MatFormFieldModule, MatSelectModule,
    BsDatepickerModule,
    TabsModule,
    HttpClientXsrfModule,
    NgxSpinnerModule,
    
    RatingModule,
    ModalModule,
    FormsModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    NgSelectModule,
    MalihuScrollbarModule,
    BnDatatableModule,
    LoadingBarHttpClientModule,
    PaginationModule,
    ImageCropperModule,
    MatTooltipModule,
    TooltipModule,
    NgxSkeletonLoaderModule,
    // CKEditorModule,
    TypeaheadModule,
    AgmCoreModule,
    PipeModule,
    NgxIntlTelInputModule,
    DataTablesModule,
    /* Components */
    SubHeaderComponent,
    ResponsiveDataTableComponent,
    GrapesJSComponent,
    PasswordComponent,
    FilterTypeAheadComponent,
    HeaderComponent,
    SidebarComponent,
    DetailsFormComponent,
    OrderDetailComponent,
    AssignDriverComponent,
    CropperModalComponent,
    // Directive
    GooglePlacesDirective,
  ],
})
export class SharedModule { }
