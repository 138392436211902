import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

// environment
import { environment } from 'src/environments/environment';

// plugins
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';

// services
import { CommonService } from '../../../common/service/services/common.service';
import { LocalStorageService } from '../../../common/service/services/local-storage.service';
import { ShowErrorService } from '../../../common/service/services/show-error.service';

// constants
import { callAPIConstants } from '../../../common/constants/callAPI-constants';
import { categoryConstants } from '../../../common/constants/categoryType-constants';
import { URLConstants } from '../../../common/constants/routerLink-constants';

@Component({
  selector: 'app-details-form',
  templateUrl: './details-form.component.html',
  styles: [],
})
export class DetailsFormComponent implements OnInit {
  callAPIConstants = callAPIConstants;
  categoryConstants = categoryConstants;
  URLConstants = URLConstants;
  uploadImgUrl = environment.uploadImgUrl;
  @Input() categoryType;
  routeParamsCategoryId: any;

  public categoryFilter: any = {};
  public file: any;
  public url: any;
  public tableConfig: any;
  public parentCatArr: any[] = [];
  parentCategoryName: any;
  categoryId1: any;

  constructor(public cd: ChangeDetectorRef, public location: Location, private activatedRoute: ActivatedRoute,
    public commonService: CommonService, public showErrorService: ShowErrorService, public localStorageService: LocalStorageService,
    private sanitize: DomSanitizer, public router: Router) {
    this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.routeParamsCategoryId = params.id;
        this.getCategoryDetails(this.routeParamsCategoryId);
      }
    });
  }

  ngOnInit() {
    if (this.categoryType === this.categoryConstants.SubCategory || this.categoryType === this.categoryConstants.SubSubCategory) {
      this.categoryType === this.categoryConstants.SubCategory ?
        this.category.parentCategory = this.localStorageService.getToken('parentCategoryId') :
        this.category.parentCategory = this.localStorageService.getToken('parentSubCategoryId');
      this.categoryType === this.categoryConstants.SubCategory ?
        this.getParentCategory(this.localStorageService.getToken('parentCategoryId')) :
        this.getParentCategory(this.localStorageService.getToken('parentSubCategoryId'));
      this.categoryType === this.categoryConstants.SubCategory ?
        this.getTypeForParentCategory = this.categoryConstants.Category :
        this.getTypeForParentCategory = this.categoryConstants.SubCategory;
      this.getParentCategoryList();
    }
  }

  /***************************************************************
  @Purpose     : Get List of Parent Category in Dropdown
  @Parameter   : NA
  @Return      : parentCategoryList
  /***************************************************************/
  parentCategoryList: Array<any> = [];
  getTypeForParentCategory: string;
  getParentCategoryList() {
    this.commonService.callApi(this.callAPIConstants.CategoryColumnData,
      { filter: { categoryName: '' }, type: this.getTypeForParentCategory }, 'post', false, false).then((success) => {
        if (success.status === 1) {
          this.parentCategoryList = success.data;
        }
      });
  }
  /***************************************************************/

  /***************************************************************
  @Purpose     : Get Parent Category
  @Parameter   : id
  @Return      : parentCategoryName
  /***************************************************************/
  getParentCategory(id) {
    this.commonService.callApi(this.callAPIConstants.GetParentCategoryName + id, '', 'get', false, false).then((success) => {
      this.parentCategoryName = success.data.categoryName;
    });
  }
  /***************************************************************/

  /***************************************************************
   @Purpose     : Get Category Details
   @Parameter   : categoryId
   @Return      : category
  /***************************************************************/
  public category: any = {};
  getCategoryDetails(categoryId) {
    this.commonService.callApi(this.callAPIConstants.getCategoryDetails + categoryId, '', 'get', false, false).then((success) => {
      if (success.status === 1) {
        this.category = success.data;
        this.category.categoryId = this.category._id;
        if (this.categoryType === this.categoryConstants.SubCategory || this.categoryType === this.categoryConstants.SubSubCategory) {
          this.category.parentCategory = this.category.parentCategory._id;
        }
      }
    });
  }
  /***************************************************************/

  /***************************************************************
   @Purpose     : Get Custom URL for Category
   @Parameter   : NA
   @Return      : category
  /***************************************************************/
  customURL() {
    if (this.category.categoryName) {
      this.category.type = this.categoryType;
      this.category.customUrl = this.category.categoryName;
      this.commonService.callApi(this.callAPIConstants.AddCategory, this.category, 'post', false, false).then((success) => {
        if (success.status === 1) {
          if(this.routeParamsCategoryId == null){
            this.category             = success.data;
            this.category.categoryId  = this.category._id;
          }
        } else { this.showErrorService.popToast('error', success.message); }
      });
    }
  }

  getCustomUrlOntabClick(event) { if (event.key === 'Tab') { this.customURL(); } }
  /***************************************************************/

  /***************************************************************
   @Purpose     : Add / Update Category
   @Parameter   : form, category
   @Return      : NA
  /***************************************************************/
  public submitted = false;
  addUpdateCategory(form, category) {
    this.submitted = true;
    if (form.valid) {
      if(category.type == undefined){category.type = 'category';}
      this.commonService.callApi(this.callAPIConstants.AddCategory, category, 'post', false, false).then((success) => {
        if (success.status === 1) {
          this.categoryType === this.categoryConstants.Category ? this.router.navigate([this.URLConstants.CATEGORY]) :
            this.categoryType === this.categoryConstants.SubCategory ?
              this.router.navigate([this.URLConstants.SUB_CATEGORY, this.localStorageService.getToken('parentCategoryId')]) :
              this.router.navigate([this.URLConstants.SUB_SUB_CATEGORY, this.localStorageService.getToken('parentSubCategoryId')]);
          this.showErrorService.popToast('success', success.message);
        } else { this.showErrorService.popToast('error', success.message); }
      });
    }
  }
  /***************************************************************/

  /***************************************************************
   @Purpose     : On Change Parent Category
   @Parameter   : $event
   @Return      : category.parentCategory
  /***************************************************************/
  changeParentCategory(event) {
    if (event._id) {
      this.category.parentCategory = event._id;
    }
  }
  /***************************************************************/

  /***************************************************************
   @Purpose     : Upload Image/ Icon
   @Parameter   : type ('image' or 'icon')
   @Return      : category.image or category.icon
  /***************************************************************/
  public files: NgxFileDropEntry[] = [];
  public fileUpload = new FormData();
  public icon: any;
  public image: any;
  public showImage: any;
  public showIcon: any;
  uploadImage(type) {
    this.fileUpload.append('file', this.file);
    this.fileUpload.append('imageType', type);
    this.fileUpload.append('categoryId', this.category.categoryId);
    this.commonService.callApi(this.callAPIConstants.CategoryFileUpload, this.fileUpload, 'post', false, true).then((success) => {
      if (success.status === 1) {
        if (type === 'image') {
          this.showImage = success.data.filepath + '?t=' + new Date().getTime();
          this.image = '';
        } else {
          this.showIcon = success.data.filepath + '?t=' + new Date().getTime();
          this.icon = '';
        }
        this.category[type] = success.data.filepath;
      } else { this.showErrorService.popToast('error', success.message); }
    });
    this.fileUpload = new FormData();
  }
  /***************************************************************

  /***************************************************************
   @Purpose     : On File Dropped
   @Parameter   : files, type ('image' or 'icon')
   @Return      : category.image or category.icon
  /***************************************************************/
  public dropped(files: NgxFileDropEntry[], type) {
    this.files = files;
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            type === 'image' ? this.image = this.sanitize.bypassSecurityTrustUrl(e.target.result) :
              this.icon = this.sanitize.bypassSecurityTrustUrl(e.target.result);
            this.file = file;
          };
          reader.readAsDataURL(file);
        });
      }
    }
  }

  public fileOver(event) { }

  public fileLeave(event) { }
  /***************************************************************/
}
