import { Component, DoCheck, OnInit } from '@angular/core';

// constants
import { URLConstants } from '../../../common/constants/routerLink-constants';
import { callAPIConstants } from '../../../common/constants/callAPI-constants';
import { languageType } from '../../../common/constants/categoryType-constants';

// Services
import { LocalStorageService } from '../../../common/service/services/local-storage.service';
import { CommonService } from '../../../common/service/services/common.service';
import { LogoutService } from '../../../common/service/services/logout.service';
import { ShowErrorService } from '../../../common/service/services/show-error.service';


@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styles: [],
})
export class HeaderComponent implements OnInit, DoCheck {
	URLConstants = URLConstants;
	callAPIConstants = callAPIConstants;
	languageList = languageType;
	public user: any;
	public firstname: any;
	public photo: any;
	public notification: number = 0;

	constructor(public localStorageService: LocalStorageService,public commonService: CommonService, public logoutService: LogoutService,private showErrorService: ShowErrorService) {
	}

	ngOnInit() {
		this.getProfile();
		// this.sidepanelOnOff()
		$('[data-toggle="offcanvas"]').on('click', function () {
			$('.sidebar-offcanvas').toggleClass('active');
		});
		$('body').addClass('asideleft-maximize');
	}

	ngDoCheck(): void {
		this.firstname = this.localStorageService.getToken('firstname');
		this.notification = parseInt(this.localStorageService.getToken('notification'));
		this.photo = this.getProfile();
	}

	/*************************************************************
	 @Purpose     : Get Profile Image
	 @Parameter   : NA
	 @Return      : url
	/*************************************************************/
	getProfile() {
		const url = this.localStorageService.getToken('photo');
		if (url == null || url === ' ' || !url) {
			return 'assets/images/NoProfile.png';
		} else {
			return url;
		}
	}
	/****************************************************************************/


	/*************************************************************
	 @Purpose     : Perform logout acton
	 @Parameter   : NA
	 @Return      : null
	/*************************************************************/
	logoutUser() {
		this.showErrorService.confirmpopToast('warning', 'Are you sure, you want to logout ?', 'LOGOUT').then((result) => {
          	if (result.value === true) {
				this.logoutService.logout();
          	}
        });
	}
	/****************************************************************************/

	public langFormData = { language: this.localStorageService.getToken('language') };
	changeLanguage(form,langFormData){
	    this.localStorageService.setToken('language',langFormData.language);
	}

	sidepanel() {
		$('body').toggleClass('asideleft-minimize');
		$('body').toggleClass('asideleft-maximize');
	}
	sidepanelOnOff() {
		$('body').toggleClass('asideleft-minimize');
		$('body').toggleClass('asideleft-maximize');
		// $("body").toggleClass("asideleft-minimize");
	}
}
