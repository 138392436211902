<ng-select class="ng-select-line" [items]="filterlist | async" placeholder="Enter {{data.colName}}" [clearable]='false'
  [multiple]="true" [dropdownPosition]="'auto'" [closeOnSelect]="true"
  (change)="selectFilterValue(data.colFieldname,$event)" [(ngModel)]="data.value" [notFoundText]="'Data not found'"
  (keydown)="data.colName == 'E-mail' || data.colName == 'Buyer Email' || data.colName == 'Order ID' 
  || data.colName == 'Return ID' || data.colName == 'Shipment ID' || 
  data.colName == 'Transaction ID' || data.colName == 'PromoCode' ||
  data.colName == 'Discount' || data.colName == 'Time Zone' ||
  data.colName == 'Vendor ID' ? '' : (data.colName == 'Mobile' ||
  data.colName == 'Phone Code' || data.colName == 'Used' || 
  data.colName == 'Pending Payout Amount' ? restrictKeyPressService.AllowNumbers($event) 
  : restrictKeyPressService.AllowChar($event))"
  [bindLabel]="this.type === 'roleList' && data.colName === 'Added By' ? 'userName' : 
  (this.type === 'brandlist' ? 'brandName': 
  ((this.type === 'categorylist' || 
  this.type === 'subCategorylist' || 
  this.type === 'subSubCategorylist') 
  ? 'categoryName' : (this.type === 'productList' || this.type=='vendorProductList' ? 
  productFilterType : (data.colName === 'Vendor' || 
  data.colName === 'Vendor Name'  ? 'vendorName' : ''))))"
  required [typeaheadMinLength]="10000000" [typeahead]="typeAheadSource" [loading]="loader">
</ng-select>