
<div class="vendor-signup">
  <div class="signup-logo" (click)="headerLogo()">
    <div class="container">
      <div class="header-bar d-flex align-items-center justify-content-between">
        <div class="header-brand-inner">
          <a class="brand-logo"  href="javascript:;">
            <img src="assets/images/riceday-logo.svg" class="header-logo" alt="Riceday" title="Riceday" /></a>
        </div>
        <div class="header-topbar" *ngIf="!signupPage">
          <ul class="topbar-nav">
            <li class="profile-dropdown">
              <a class="dropdown-toggle" id="profileDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
                <span class="user-info">
                  <span class="user-name">
                    {{vendorDetails?.fullName}}
                  </span>
                </span>
              </a>
              <div class="dropdown-menu dropdown-menu-right p-0 profile-menu" aria-labelledby="profileDropdown">
                <div class="dropdown-divider mt-2 mb-0"></div>
                <a class="dropdown-item logout" href="javascript:;" (click)='logoutUser()'>
                  <span class="fa fa-power-off"></span><span>Logout</span>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="vendor-process">
    <div class="container">
      <ul class="step-bar" id="bar">
        <li class="active"><span>Vendor Information</span></li>
        <li [ngClass]="gst_details ||bank_info?'active':''"><span>GST Details</span></li>
        <li [ngClass]="bank_info?'active':''"><span>Bank Information</span></li>
      </ul>
    </div>
  </div>
  
  <div class="vendor-form">
    <div class="container">
      <div class="authentic-wrapper-signup" *ngIf="vender">
        <div class="signup-heading">
          <h3>Vendor Information</h3>
        </div>
        <div class="signup-page">
          <form class="example-form" #vendorForm="ngForm" (ngSubmit)="next('vender')"
            content-type="application/x-www-form-urlencoded" autocomplete="off">
            <div class='form-group'>
              <label htmlFor="Email">Name</label>
              <div class="w-100" [ngClass]="((submitted || name.touched) && name.invalid)?'has-danger':''">
                <input class="form-control"  (keydown)="restrictKeyPressService.AllowChar($event)"placeholder="Enter Name" type="text" name="name" autocomplete="off"
                  [(ngModel)]="vendorDetails.fullName" value="" id="name" required #name="ngModel" />
                <div class="error-block text-left" *ngIf="((submitted || name.touched) && name.invalid)">
                  {{showErrorService.showError('name', name.errors)}}
                </div>
              </div>
            </div>
            <div class='form-group'>
              <label htmlFor="Email">Business Name</label>
              <div class="w-100"
                [ngClass]="((submitted || businessName.touched) && businessName.invalid)?'has-danger':''">
                <input class="form-control"  (keydown)="restrictKeyPressService.AllowChar($event)" placeholder="Enter Business Name" type="text" name="businessName"
                  autocomplete="off" [(ngModel)]="vendorDetails.businessName" value="" id="businessName" required
                  #businessName="ngModel" />
                <div class="error-block text-left" *ngIf="((submitted || businessName.touched) && businessName.invalid)">
                  {{showErrorService.showError('businessName', businessName.errors)}}
                </div>
              </div>
            </div>
            <div class='form-group'>
              <label htmlFor="Email">Contact Number</label>
              <div class="w-100" [ngClass]="((submitted || vendorForm.form.controls['contactNo']?.errors?.validatePhoneNumber|| contactNo.touched) && contactNo.invalid)?'has-danger':''">
                <ngx-intl-tel-input required maxLength="13" class="contact-no" [ngClass]="((submitted ||vendorForm.form.controls['contactNo']?.errors?.validatePhoneNumber|| contactNo.touched) && contactNo.invalid)?'has-danger custom':'custom'" [enableAutoCountrySelect]="true" placeholder="Enter Contact Number" [enablePlaceholder]="false" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false" [selectedCountryISO]="vendorDetails.countryShortName?vendorDetails.countryShortName:'in'"  [tooltipField]="TooltipLabel.Name" [phoneValidation]="true" [separateDialCode]="false"[numberFormat]="PhoneNumberFormat.National" id="contactNo" name="contactNo" placeholder="Phone Number" #contactNo=ngModel [(ngModel)]="vendorDetails.contactNo" autocomplete="off">
                </ngx-intl-tel-input>
                  <div class="error-block" *ngIf="((submitted || contactNo.touched) && contactNo.invalid)">
                    {{showErrorService.showError('contactNo', contactNo.errors)}}
                </div>
                <div class="error-block" *ngIf="vendorForm.form.controls['contactNo']?.errors?.validatePhoneNumber">
                  Invalid Contact Number
                </div>
              </div>
            </div>
            <div class='form-group'>
              <label htmlFor="Email">Email Id</label>
              <div class="w-100" [ngClass]="((submitted || emailId.touched) && emailId.invalid)?'has-danger':''">
                <input class="form-control" placeholder="Enter Email Id" type="emailId" name="emailId"
                  pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" autocomplete="off" [disabled]="!signupPage"
                  [(ngModel)]="vendorDetails.emailId" value="" id="emailId" required #emailId="ngModel" />
                <div class="error-block text-left" *ngIf="((submitted || emailId.touched) && emailId.invalid)">
                  {{showErrorService.showError('emailId', emailId.errors)}}
                </div>
              </div>
            </div>
            <div class='form-group'>
              <label htmlFor="Email">Address</label>
              <div class="w-100" [ngClass]="((submitted || address.touched) && address.invalid)?'has-danger':''">
                <input google-place (onSelect)="setAddress($event)"  (paste)="restrictPaste($event)" autocomplete="off"
                  class="form-control" type="text" placeholder="Enter Address" name="address" #address="ngModel"
                  [(ngModel)]="vendorDetails.address" required>
                <div class="error-block text-left" *ngIf="((submitted || address.touched) && address.invalid)">
                  {{showErrorService.showError('address', address.errors)}}
                </div>
              </div>
            </div>
            <div class='form-group'>
              <label htmlFor="Email">Country</label>
              <div class="w-100">
                <input autocomplete="off" disabled class="form-control" type="text" placeholder="Country" name="country"
                  [(ngModel)]="vendorDetails.country">
              </div>
            </div>
            <div class='form-group'>
              <label htmlFor="Email">State</label>
              <div class="w-100">
                <input autocomplete="off" class="form-control" type="text" placeholder="State" name="state"
                  [(ngModel)]="vendorDetails.state" disabled>
              </div>
            </div>
            <div class='form-group'>
              <label htmlFor="Email">City</label>
              <div class="w-100">
                <input autocomplete="off" class="form-control" type="text" placeholder="city" name="City"
                  [(ngModel)]="vendorDetails.city" disabled>
              </div>
            </div>
            <div class='form-group' *ngIf="signupPage">
              <label htmlFor="Password">Password</label>
              <div class="w-100" [ngClass]="((submitted || password.touched) && password.invalid)?'has-danger':''">
                <input class="form-control" placeholder="Enter Password"  pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$" type="password" name="password"
                  [(ngModel)]="vendorDetails.password"  value="" autocomplete="off" id="password" required #password="ngModel" />
                <div class="error-block text-left" *ngIf="((submitted || password.touched) && password.invalid)">
                  {{showErrorService.showError('password', password.errors)}}
                </div>
              </div>
            </div>
            <div class='form-group' *ngIf="signupPage">
              <label htmlFor="Password">Confirm Password</label>
              <div class="w-100"
              [ngClass]="((submitted || confirmPassword.touched || confirmPassword.dirty) && vendorDetails.confirmPassword != vendorDetails.password) || (submitted || confirmPassword.touched) && confirmPassword.invalid?'has-danger':''">
                <input class='form-control' minlength="8" maxlength="20" type="password" name="confirmPassword"
                  [(ngModel)]="vendorDetails.confirmPassword" placeholder="Enter Confirm Password" autocomplete="off" required
                  #confirmPassword="ngModel" />
                <div class="form-control-feedback error-block"
                  *ngIf="((submitted || confirmPassword.touched) && confirmPassword.invalid)">
                  {{showErrorService.showError('confirmPassword', confirmPassword.errors)}}
                </div>
                <div class="form-control-feedback error-block"
                  *ngIf="((submitted || confirmPassword.touched || confirmPassword.dirty) && confirmPassword.valid && (vendorDetails.confirmPassword != vendorDetails.password))">
                  Password & Confirm Password must be same.
                </div>
              </div>
            </div>
            <div class='mt-5'>
              <button type="submit"  class='btn btn-block btn-primary m-0' [disabled]="!vendorForm.form.valid">Next</button>
            </div>
          </form>
        </div>
      </div>
      <div class="authentic-wrapper-signup" *ngIf="gst_details">
        <div class="signup-heading text-center">
          <h3>GST Details</h3>
        </div>
        <div class="row">
          <div class="signup-page">
            <form name="gstfrm" #gstFrm="ngForm" (ngSubmit)="next('gst_details')">
              <div class='form-group row'>
                <div class="col-md-12" [ngClass]="((submitted || gstRegNo.touched) && gstRegNo.invalid)?'has-danger':''">
                  <label htmlFor="Email">Registered Number</label>
                  <input class="form-control" maxlength="15" pattern="([0][1-9]|[1-2][0-9]|[3][0-7])([A-Z]{5})([0-9]{4})([A-Z]{1}[1-9A-Z]{1})([Z]{1})([0-9A-Z]{1})+$" placeholder="Enter Registered Number" type="text" gstRegNo="gstRegNo" autocomplete="off"
                    [(ngModel)]="vendorDetails.gstRegNo" value="" id="gstRegNo" required #gstRegNo="ngModel" name="gstRegNo"/>
                  <div class="error-block text-left" *ngIf="((submitted || gstRegNo.touched) && gstRegNo.invalid)">
                    {{showErrorService.showError('gstRegNo', gstRegNo.errors)}}
                  </div>
                </div>
              </div>
              <div class='form-group row'>
                <div class="col-md-12" [ngClass]="((submitted || gstRegCompanyName.touched) && gstRegCompanyName.invalid)?'has-danger':''">
                  <label htmlFor="Email">Registered Company Name</label>
                  <input class="form-control" placeholder="Enter Registered Company Name" type="text" gstRegCompanyName="gstRegCompanyName" autocomplete="off"
                    [(ngModel)]="vendorDetails.gstRegCompanyName" value="" id="gstRegCompanyName" required #gstRegCompanyName="ngModel" name="gstRegCompanyName" />
                  <div class="error-block text-left" *ngIf="((submitted || gstRegCompanyName.touched) && gstRegCompanyName.invalid)">
                    {{showErrorService.showError('gstRegCompanyName', gstRegCompanyName.errors)}}
                  </div>
                </div>
              </div>
              <div class='form-group row'>
                <div class="col-md-12" [ngClass]="((submitted || gstRegCompanyAddress.touched) && gstRegCompanyAddress.invalid)?'has-danger':''">
                  <label htmlFor="Email">Registered Company Address</label>
                  <input class="form-control" placeholder="Enter Registered Company Address" type="text" gstRegCompanyAddress="gstRegCompanyAddress" autocomplete="off"
                    [(ngModel)]="vendorDetails.gstRegCompanyAddress" value="" id="gstRegCompanyAddress" required #gstRegCompanyAddress="ngModel"  name="gstRegCompanyAddress" />
                  <div class="error-block text-left" *ngIf="((submitted || gstRegCompanyAddress.touched) && gstRegCompanyAddress.invalid)">
                    {{showErrorService.showError('gstRegCompanyAddress', gstRegCompanyAddress.errors)}}
                  </div>
                </div>
              </div>
              <div class="row  mt-5">
                <div class="col-lg-6">
                  <button class="btn w-100 m-0 btn-primary" (click)="cancel('gst_details')">Previous</button>
                </div>
                <div class="col-lg-6">
                  <button class="btn w-100 m-0 btn-primary" [disabled]="!gstFrm.form.valid">Next</button>
                </div>
              </div>
            </form>
          </div>
    
        </div>
      </div>
    
      <div class="authentic-wrapper-signup" *ngIf="bank_info">
        <div class="signup-heading text-center">
          <h3>Bank Information</h3>
        </div>
        <div class="row">
          <div class="signup-page">
            <form name="bank_info" #bank_info="ngForm" (ngSubmit)="next('bank_info')">
              <div class='form-group row'>
                <div class="col-md-12" [ngClass]="((submitted || bankName.touched) && bankName.invalid)?'has-danger':''">
                  <label htmlFor="Email">Bank Name</label>
                  <input class="form-control" placeholder="Enter Bank Name" type="text" bankName="bankName" autocomplete="off"
                    [(ngModel)]="vendorDetails.bankName" value="" id="bankName" required #bankName="ngModel" name="bankName"/>
                  <div class="error-block text-left" *ngIf="((submitted || bankName.touched) && bankName.invalid)">
                    {{showErrorService.showError('bankName', bankName.errors)}}
                  </div>
                </div>
              </div>
              <div class='form-group row'>
                <div class="col-md-12" [ngClass]="((submitted || bankAccountNo.touched) && bankAccountNo.invalid)?'has-danger':''">
                  <label htmlFor="Email">Bank Account Number</label>
                  <input class="form-control" placeholder="Enter Bank Account Number" type="text" name="bankAccountNo"
                    autocomplete="off" [(ngModel)]="vendorDetails.bankAccountNo"
                    (keydown)="restrictKeyPressService.AllowNumbers($event)" value="" id="bankAccountNo" required
                    #bankAccountNo="ngModel"  />
                  <div class="error-block text-left" *ngIf="((submitted || bankAccountNo.touched) && bankAccountNo.invalid)">
                    {{showErrorService.showError('bankAccountNo', bankAccountNo.errors)}}
                  </div>
                </div>
              </div>
              <div class='form-group row'>
                <div class="col-md-12" [ngClass]="((submitted || bankIFSCCode.touched) && bankIFSCCode.invalid)?'has-danger':''">
                  <label htmlFor="Email">Bank IFSC Code</label>
                  <input class="form-control" placeholder="Enter Bank IFSC Code" type="text" name="bankIFSCCode"
                    autocomplete="off" [(ngModel)]="vendorDetails.bankIFSCCode"
                    value="" id="bankIFSCCode" required
                    #bankIFSCCode="ngModel"  />
                  <div class="error-block text-left" *ngIf="((submitted || bankIFSCCode.touched) && bankIFSCCode.invalid)">
                    {{showErrorService.showError('bankIFSCCode', bankIFSCCode.errors)}}
                  </div>
                </div>
              </div>
              <div class='form-group row'>
                <div class="col-md-12" [ngClass]="((submitted || bankAccountName.touched) && bankAccountName.invalid)?'has-danger':''">
                  <label htmlFor="Email">Bank Account Holder Name</label>
                  <input class="form-control" placeholder="Enter Bank Account Holder Number" type="text" name="bankAccountName"
                    autocomplete="off" [(ngModel)]="vendorDetails.bankAccountName"
                    value="" id="bankAccountName" required
                    #bankAccountName="ngModel"  />
                  <div class="error-block text-left" *ngIf="((submitted || bankAccountName.touched) && bankAccountName.invalid)">
                    {{showErrorService.showError('bankAccountName', bankAccountName.errors)}}
                  </div>
                </div>
              </div>
              <div class='form-group row'>
                <div class="col-md-12" [ngClass]="((submitted || bankAccountType.touched) && bankAccountType.invalid)?'has-danger':''">
                  <label htmlFor="Email">Bank Account Type</label>
                  <ng-select class="ng-select-line"
                  [ngClass]="((submitted || bankAccountType.touched) && bankAccountType.invalid)?'has-danger':''"
                  [items]="bankAccountTypeList"  placeholder="Select Bank Account Type" [clearable]='false' [searchable]='true' autocomplete="false" [dropdownPosition]="'auto'" name="bankAccountType" #bankAccountType="ngModel" [(ngModel)]="vendorDetails.bankAccountType" required>
              </ng-select>
                  <div class="error-block text-left" *ngIf="((submitted || bankAccountType.touched) && bankAccountType.invalid)">
                    {{showErrorService.showError('bankAccountType', bankAccountType.errors)}}
                  </div>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-lg-6">
                  <button class="btn w-100 m-0 btn-primary" (click)="cancel('bank_info')">Previous</button>
                </div>
                <div class="col-lg-6">
                  <button (click)="register()" class="btn w-100 m-0 btn-primary" [disabled]="!bank_info.form.valid">{{signupPage?'Register':'Update'}}</button>
                </div>
              </div>
            </form>
          </div>
    
        </div>
      </div>
    </div>
  </div>
</div>