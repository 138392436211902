<nav class="nav" role="navigation">
  <div class="header-brand">
      <div class="header-brand-inner">
      <a class="brand-logo" [routerLink]="[URLConstants.DASHBOARD]" href="javascript:;">
        <img [src]="globalSettings ? uploadImgUrl+globalSettings?.siteLogoLarge : 'assets/images/riceday-logo.svg'" class="header-logo" alt="{{globalSettings ? globalSettings?.siteName : 'Fresh Mart' }}" title="{{globalSettings ? globalSettings?.siteName : 'Fresh Mart' }}" />
        <img [src]="globalSettings ? uploadImgUrl+globalSettings.siteLogoSmall : 'assets/images/resposive-logo' " class="header-icon" alt="{{globalSettings ?  globalSettings?.siteName :'Fresh Mart' }}" title="{{globalSettings ?  globalSettings?.siteName : 'Fresh Mart' }}" /></a>
      <div class="brand-tools">
      </div>
    </div>
  </div>
  <div class="text-center user-profile">
    <div class="thumb-img">
      <img [src]="photo  ? photo : 'assets/images/NoProfile.png'" alt="no img" />
    </div>
    <span>
      {{firstname}}
    </span>
  </div>
  <ul class="sidebar nav__list" (click)="checkSideNav($event)">
    <!-- DASHBOARD -->
    <li routerLinkActive='active' class="nav-item">
      <a class="nav-link" [routerLink]="[URLConstants.DASHBOARD]" href="javascript:;">
        <span class="nav-link-icon fa fa-bar-chart-o"></span>
        <span class="nav-link-text">Dashboard</span>
      </a>
    </li> 
    <!-- PRODUCT CATALOG -->
    <li routerLinkActive='active' class="nav-item">
      <a class="nav-link" href="javascript:;" [routerLink]="[URLConstants.PRODUCTS]">
        <span class="nav-link-icon fa fa fa-sitemap"></span>
        <span class="nav-link-text">
          <span [ngClass]="onProductModule ? 'sub-nav-active' : ''">Product Management</span></span>
      </a>
    </li>
    
    <!-- ORDERS -->
    <li routerLinkActive='active' class="nav-item">
      <a class="nav-link" href="javascript:;" [routerLink]="[URLConstants.ORDER]">
        <span class="nav-link-icon fa fa-shopping-cart"></span>
        <span class="nav-link-text">
          <span [ngClass]="onOrderModule ? 'sub-nav-active' : ''">Order Status</span></span>
      </a>
    </li>

    <!-- Review & Ratings -->
    <li routerLinkActive='active' class="nav-item">
      <a class="nav-link" href="javascript:;" [routerLink]="[URLConstants.RATING]">
        <span class="nav-link-icon fa fa-star"></span>
        <span class="nav-link-text">
          <span [ngClass]="onReviewModule ? 'sub-nav-active' : ''">Product Reviews</span></span>
      </a>
    </li>

    <!-- Manage  Payouts-->
    <li routerLinkActive='active' class="nav-item">
      <a class="nav-link" href="javascript:;" [routerLink]="[URLConstants.PAYOUTS]">
        <span class="nav-link-icon fa fa-dollar"></span>
        <span class="nav-link-text">
          <span [ngClass]="onPayoutModule ? 'sub-nav-active' : ''">Payment Status</span></span>
      </a>
    </li>
    
    
    <!-- Settings -->
    <li class="nav-item" routerLinkActive='active' >
      <a class="nav-link" href="javascript:;"[routerLink]="[URLConstants.ACCOUNT_SETTING]">
        <span class="nav-link-icon fa fa-cog" aria-hidden="true"></span>
        <span class="nav-link-text"><span [ngClass]="onSettingsModule ? 'sub-nav-active' : ''">Settings</span></span>
      </a>
    </li>
    <li class="nav-item" routerLinkActive='active' >
      <a class="nav-link" href="javascript:;"[routerLink]="[URLConstants.TICKETLISTING]">
        <span class="nav-link-icon fa fa-long-arrow-right" aria-hidden="true"></span>
        <span class="nav-link-text"><span [ngClass]="onTicketsModule ? 'sub-nav-active' : ''">Ticket Listing</span></span>
      </a>
    </li>
  </ul>
</nav>