import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from './common/service/services/local-storage.service';
import { URLConstants } from '../app/common/constants/routerLink-constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [],
})
export class AppComponent { 
constructor(private localStorageService:LocalStorageService,private router:Router){}
URLConstants=URLConstants;
	ngOnInit() { 
		this.localStorageService.getToken('socialLogin');
		if(this.localStorageService.getToken('socialLogin')){
			if(this.localStorageService.getToken('profileCompleted')==='false'){this.router.navigate([this.URLConstants.UPDTAEINFORMATION])}
		}
		var header = $(".start-style");
        $(window).scroll(function() {
            var scroll = $(window).scrollTop();
	        if (scroll >= 200) {
	            header.removeClass('start-style').addClass("scroll-on");
	        } else {
	            header.removeClass("scroll-on").addClass('start-style');
	        }

	    	var $scrollTop = $(window).scrollTop();
		    if ($scrollTop > 60) {
		      $("body").addClass("navbar-scrolled");
		    }else{
		      $("body").removeClass("navbar-scrolled");
		    }
		    if ($scrollTop > 20) {
		      $("body").addClass("page-scrolled");
		    }
		    else{
		      $("body").removeClass("page-scrolled");
		    }
	  	});
	}

}
