import { Component, DoCheck, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

// constants
import { adminRoleConstants } from '../../../common/constants/categoryType-constants';
import { URLConstants } from '../../../common/constants/routerLink-constants';
import { callAPIConstants } from '../../../common/constants/callAPI-constants';

// Services
import { CommonService } from '../../../common/service/services/common.service';
import { LocalStorageService } from '../../../common/service/services/local-storage.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styles: [],
})
export class SidebarComponent implements OnInit, DoCheck {
  URLConstants = URLConstants;
  callAPIConstants = callAPIConstants;
  adminRoleConstants = adminRoleConstants;
  uploadImgUrl = environment.uploadImgUrl;
  globalSettings: any;

  public user: any;
  public accessPermission: any;
  public firstname: any;
  public photo: any;
  public onUsersModule: any;
  public onCategoryModule: any;
  public onProductModule: any;
  public onAdminUsersModule: any;
  public onHomeModule: any;
  public onGeneralModule: any;
  public onSocialMediaModule: any;
  public onCmsModule: any;
  public onEmailTemplateModule: any;
  public onManageRolesModule: any;
  public onBrandModule: any;
  public onOrderModule: any;
  public onShipmentModule: any;
  public onReturnModule: any;
  public onTransactionModule: any;
  public onVendorModule: any;
  public onGlobalSettingsModule: any;
  public onNotificationModule: any;
  public onDriversModule: any;
  public onSMTPModule: any;
  public onBulkUploadsModule: any;
  public onReviewModule: any;
  public onPromocodeModule: any;
  public onPayoutModule: any;
  public onMeasurementModule: any;
  public onFAQModule: any;
  public accessToken: any;
  public onSettingsModule: any;
public onTicketsModule :any;
  constructor(public localStorageService: LocalStorageService, private commonService: CommonService, private router: Router) {
    // START: Role Permission //
    // this.accessPermission = this.commonService.decrypt(this.localStorageService.getToken('role_permission'));
    // END: Role Permission //
    this.accessToken = this.localStorageService.getToken('accessToken');
    
    /* Will be triggered on every route change */
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.onUsersModule = this.router.url.includes(this.URLConstants.USERS);
        if (this.router.url.includes(this.URLConstants.SUB_CATEGORY_URL)
          || this.router.url.includes(this.URLConstants.SUB_SUB_CATEGORY_URL)
          || this.router.url.includes(this.URLConstants.CATEGORY)) {
          this.onCategoryModule = true;
        } else { this.onCategoryModule = false; }
        this.onProductModule = this.router.url.includes(this.URLConstants.PRODUCTS);
        this.onBrandModule = this.router.url.includes(this.URLConstants.BRAND);
        this.onOrderModule = this.router.url.includes(this.URLConstants.ORDER);
        this.onShipmentModule = this.router.url.includes(this.URLConstants.SHIPMENT);
        this.onReturnModule = this.router.url.includes(this.URLConstants.RETURN);
        this.onTransactionModule = this.router.url.includes(this.URLConstants.TRANSACTION);
        this.onAdminUsersModule = this.router.url.includes(this.URLConstants.ADMINUSER_LIST);
        this.onHomeModule = this.router.url.includes(this.URLConstants.HOME);
        this.onGeneralModule = this.router.url.includes(this.URLConstants.GENERAL);
        this.onSocialMediaModule = this.router.url.includes(this.URLConstants.SOCIAL_MEDIA);
        this.onCmsModule = this.router.url.includes(this.URLConstants.CMS);
        this.onEmailTemplateModule = this.router.url.includes(this.URLConstants.EMAIL_TEMPLATE_list);
        this.onManageRolesModule = this.router.url.includes(this.URLConstants.MANAGE_ROLES);
        this.onVendorModule = this.router.url.includes(this.URLConstants.VENDOR);
        this.onGlobalSettingsModule = this.router.url.includes(this.URLConstants.GLOBAL_SETTINGS);
        this.onNotificationModule = this.router.url.includes(this.URLConstants.NOTIFICATION);
        this.onDriversModule = this.router.url.includes(this.URLConstants.DRIVER);
        this.onSMTPModule = this.router.url.includes(this.URLConstants.SMTP_SETTING);
        this.onBulkUploadsModule = this.router.url.includes(this.URLConstants.BULKUPLOADS);
        this.onReviewModule = this.router.url.includes(this.URLConstants.RATING);
        this.onPromocodeModule = this.router.url.includes(this.URLConstants.PROMOCODE);
        this.onPayoutModule = this.router.url.includes(this.URLConstants.PAYOUTS);
        this.onMeasurementModule = this.router.url.includes(this.URLConstants.MEASUREMENT);
        this.onFAQModule = this.router.url.includes(this.URLConstants.FAQ);
        this.onSettingsModule = this.router.url.includes(this.URLConstants.ACCOUNT_SETTING);
        this.onTicketsModule = this.router.url.includes(this.URLConstants.TICKETLISTING);


        //Update Notifications count
        if(this.accessToken && !this.router.url.includes(this.URLConstants.LOGIN)){
          this.commonService.callApi(this.callAPIConstants.getNotificationsCount,'', 'get', false, false).then((success) => {
            if (success.status === 1) {
              this.localStorageService.setToken('notification',success.data.notificationsCount);
            }
          });
        }
      }
      if (event instanceof NavigationStart) {
        this.onUsersModule = this.router.url.includes(this.URLConstants.USERS);
        if (this.router.url.includes(this.URLConstants.SUB_CATEGORY_URL)
          || this.router.url.includes(this.URLConstants.SUB_SUB_CATEGORY_URL)
          || this.router.url.includes(this.URLConstants.CATEGORY)) {
          this.onCategoryModule = true;
        } else { this.onCategoryModule = false; }
        this.onProductModule = this.router.url.includes(this.URLConstants.PRODUCTS);
        this.onBrandModule = this.router.url.includes(this.URLConstants.BRAND);
        this.onOrderModule = this.router.url.includes(this.URLConstants.ORDER);
        this.onShipmentModule = this.router.url.includes(this.URLConstants.SHIPMENT);
        this.onReturnModule = this.router.url.includes(this.URLConstants.RETURN);
        this.onTransactionModule = this.router.url.includes(this.URLConstants.TRANSACTION);
        this.onAdminUsersModule = this.router.url.includes(this.URLConstants.ADMINUSER_LIST);
        this.onHomeModule = this.router.url.includes(this.URLConstants.HOME);
        this.onGeneralModule = this.router.url.includes(this.URLConstants.GENERAL);
        this.onSocialMediaModule = this.router.url.includes(this.URLConstants.SOCIAL_MEDIA);
        this.onCmsModule = this.router.url.includes(this.URLConstants.CMS);
        this.onEmailTemplateModule = this.router.url.includes(this.URLConstants.EMAIL_TEMPLATE_list);
        this.onManageRolesModule = this.router.url.includes(this.URLConstants.MANAGE_ROLES);
        this.onVendorModule = this.router.url.includes(this.URLConstants.VENDOR);
        this.onGlobalSettingsModule = this.router.url.includes(this.URLConstants.GLOBAL_SETTINGS);
        this.onNotificationModule = this.router.url.includes(this.URLConstants.NOTIFICATION);
        this.onDriversModule = this.router.url.includes(this.URLConstants.DRIVER);
        this.onSMTPModule = this.router.url.includes(this.URLConstants.SMTP_SETTING);
        this.onBulkUploadsModule = this.router.url.includes(this.URLConstants.BULKUPLOADS);
        this.onReviewModule = this.router.url.includes(this.URLConstants.RATING);
        this.onPromocodeModule = this.router.url.includes(this.URLConstants.PROMOCODE);
        this.onPayoutModule = this.router.url.includes(this.URLConstants.PAYOUTS);
        this.onMeasurementModule = this.router.url.includes(this.URLConstants.MEASUREMENT);
        this.onFAQModule = this.router.url.includes(this.URLConstants.FAQ);
        this.onSettingsModule = this.router.url.includes(this.URLConstants.ACCOUNT_SETTING);
        this.onTicketsModule = this.router.url.includes(this.URLConstants.TICKETLISTING);

        //Update Notifications count
        // if(this.accessToken && !this.router.url.includes(this.URLConstants.LOGIN)){
        //   this.commonService.callApi(this.callAPIConstants.getNotificationsCount,'', 'get', false, false).then((success) => {
        //     if (success.status === 1) {
        //       this.localStorageService.setToken('notification',success.data.notificationsCount);
        //     }
        //   });
        // }
      }
    });
    /* Will be triggered on every route change */
  }

  isSidebarOpen: boolean;

  ngOnInit() {
    this.globalSettings = JSON.parse(this.localStorageService.getToken('globalSetting'));
    $('.asideleft-maximize .nav a').click(function () {
      const link = $(this);
      const closest_ul = link.closest('.asideleft-maximize ul');
      const parallel_active_links = closest_ul.find('.active');
      const closest_li = link.closest('li');
      const link_status = closest_li.hasClass('active');
      let count = 0;

      closest_ul.find('ul').slideUp('fast', function () {
        if (++count === closest_ul.find('ul').length) {
          parallel_active_links.removeClass('active');
        }
      });

      if (!link_status) {
        closest_li.children('ul').slideDown('fast');
        closest_li.addClass('active');
      }

    });
  }


  ngDoCheck(): void {
    this.firstname = this.localStorageService.getToken('firstname');
    this.photo = this.localStorageService.getToken('photo');
    this.globalSettings = JSON.parse(this.localStorageService.getToken('globalSetting'));
  }

  checkSideNav(e) {
    
  }

  asideLeftHide() {
    $('body').removeClass('asideleft-on');
  }

}
