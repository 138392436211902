<!-- BEGIN : Show Skeleton Loader -->
<div class="content-wrapper" [hidden]="!commonService.showSkeletonLoader">
  <div class="card animated fadeIn">
    <div class="card-body">
      <div class="container mt-3">
        <div class="form-group row">
          <div class="col-lg-2 col-sm-3">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
          <div class="col-md-8 co-sm-7">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-lg-2 col-sm-3">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
          <div class="col-md-8 co-sm-7">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-lg-2 col-sm-3">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
          <div class="col-md-8 co-sm-7">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-lg-2 col-sm-3">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
          <div class="col-md-8 co-sm-7">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-lg-2 col-sm-3">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
          <div class="col-md-8 co-sm-7">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8"></div>
          <div class="col-md-2">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
          <div class="col-md-2">
            <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END : Show Skeleton Loader -->

<div class="content-wrapper" [hidden]="commonService.showSkeletonLoader">
  <div class="card animated fadeIn">
    <form #form="ngForm" (ngSubmit)="addUpdateCategory(form,category)">
      <div class="card-body">
        <div class="container mt-3">
          <div class="row mb-3">
            <div class="col-md-2 text-right">
              <label class="mt-2"><strong>Name<span class="error-block">* </span> : </strong></label>
            </div>
            <div class="col-md-7"
              [ngClass]=" ((submitted || categoryName.touched) && categoryName.invalid)?'has-danger':''">
              <input autocomplete="off" class="form-control" type="text" id="inputName"
                placeholder="Enter Category Name" (blur)="customURL()" name="categoryName"
                #categoryName="ngModel" [(ngModel)]="category.categoryName" required>
              <div class="error-block text-left" *ngIf="((submitted || categoryName.touched) && categoryName.invalid)">
                {{showErrorService.showError('categoryName', categoryName.errors)}}
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-2 text-right">
              <label class="mt-2"><strong>Custom Url<span class="error-block">* </span> :</strong></label>
            </div>
            <div class="col-md-7" [ngClass]="((submitted || customUrl.touched) && customUrl.invalid)?'has-danger':''">
              <input autocomplete="off" class="form-control" type="text" id="inputName" placeholder="Enter Custom Url"
                name="customUrl" #customUrl="ngModel" [(ngModel)]="category.customUrl" required>
              <div class="error-block text-left" *ngIf="((submitted || customUrl.touched) && customUrl.invalid)">
                {{showErrorService.showError('customUrl', customUrl.errors)}}
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-2 text-right">
              <label class="mt-2"><strong>Name(Arabic)<span class="error-block">* </span> : </strong></label>
            </div>
            <div class="col-md-7"
              [ngClass]=" ((submitted || categoryNameAr.touched) && categoryNameAr.invalid)?'has-danger':''">
              <input autocomplete="off" class="form-control" type="text" id="inputName"
                placeholder="Enter Category Name in Arabic" (blur)="customURL()" name="categoryNameAr"
                #categoryNameAr="ngModel" [(ngModel)]="category.categoryNameAr" required>
              <div class="error-block text-left" *ngIf="((submitted || categoryNameAr.touched) && categoryNameAr.invalid)">
                {{showErrorService.showError('categoryName', categoryNameAr.errors)}}
              </div>
            </div>
          </div>
          
          <div class="row mb-3">
            <div class="col-md-2 text-right">
              <label class="mt-2"><strong>Custom Url(Arabic)<span class="error-block">* </span> :</strong></label>
            </div>
            <div class="col-md-7" [ngClass]="((submitted || customUrlAr.touched) && customUrlAr.invalid)?'has-danger':''">
              <input autocomplete="off" class="form-control" type="text" id="inputName" placeholder="Enter Custom Url in Arabic"
                name="customUrlAr"  #customUrlAr="ngModel" [(ngModel)]="category.customUrlAr" required>
              <div class="error-block text-left" *ngIf="((submitted || customUrlAr.touched) && customUrlAr.invalid)">
                {{showErrorService.showError('customUrl', customUrlAr.errors)}}
              </div>
            </div>
          </div>
          <div class="row mb-3"
            *ngIf="router.url.includes(URLConstants.SUB_CATEGORY_DETAIL) || router.url.includes(URLConstants.ADD_SUB_CATEGORY) || router.url.includes(URLConstants.SUB_SUB_CATEGORY_DETAIL) || router.url.includes(URLConstants.ADD_SUB_SUB_CATEGORY)">
            <div class="col-md-2 text-right">
              <label class="mt-2"><strong>Parent Category<span class="error-block">* </span> :</strong></label>
            </div>
            <div class="col-md-7">
              <ng-select [items]="parentCategoryList" name="parentCategoryName" bindLabel="categoryName"
                [clearable]='false' bindValue="categoryName" [(ngModel)]="parentCategoryName"
                (change)="changeParentCategory($event)" required>
              </ng-select>
            </div>
          </div>
          <!-- Image -->
          <div class="row mb-3">
            <div class="col-md-2 text-right">
              <label class="mt-2"><strong>Image : </strong></label>
            </div>
            <div class="col-md-3">
              <ngx-file-drop dropZoneLabel="Choose a file or drag it here" (onFileDrop)="dropped($event,'image')"
                (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)" [accept]="['.jpg','.jpeg']"
                [multiple]=false>
                <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                  <em className="fa fa-upload"></em>
                  <span (click)="openFileSelector($event)" class="file-uploder">
                    <span class="fa fa-upload"></span>
                    <span><strong> Choose a file</strong> or drag it here</span>
                  </span>
                </ng-template>
              </ngx-file-drop>
              <div *ngIf="image">
                <img [src]="image ? image : 'assets/images/no-image-icon.png'" alt="no img">
                <em class="fa fa-times" aria-hidden="true" (click)="image=''"></em>
              </div>
              <span class="floatRight"><button type="button" class="btn btn-primary mt-3" (click)="uploadImage('image')"
                  [disabled]="!image">Upload
                  Image</button></span>
            </div>
            <div class="col-md-4">
              <img
                [src]="showImage ? uploadImgUrl + showImage : (category.image ? uploadImgUrl + category.image: 'assets/images/no-image-icon.png')"
                alt="no img" height="100px">
              <span class="ml-2">Image</span>
            </div>
          </div>
          <!-- Icon -->
          <div class="row mb-3" *ngIf="router.url.includes(URLConstants.CATEGORY)">
            <div class="col-md-2 text-right">
              <label class="mt-2"><strong>Icon : </strong></label>
            </div>
            <div class="col-md-3">
              <ngx-file-drop dropZoneLabel="Choose a file or drag it here" (onFileDrop)="dropped($event,'icon')"
                (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)" [accept]="'.png'" [multiple]=false>
                <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                  <span (click)="openFileSelector($event)" class="file-uploder">
                    <span class="fa fa-upload"></span>
                    <span><strong>Choose a file</strong> or drag it here</span>
                  </span>
                </ng-template>
              </ngx-file-drop>
              <div *ngIf="icon">
                <img [src]="icon ? icon : 'assets/images/no-image-icon.png'" alt="no img">
                <em class="fa fa-times" aria-hidden="true" (click)="icon=''"></em>
              </div>
              <span class="floatRight"><button type="button" class="btn btn-primary mt-3" (click)="uploadImage('icon')"
                  [disabled]="!icon">Upload
                  Icon</button></span>
            </div>
            <div class="col-md-4">
              <img
                [src]="showIcon ? uploadImgUrl + showIcon : (category.icon ? uploadImgUrl + category.icon: 'assets/images/no-image-icon.png')"
                alt="no img" height="100px">
              <span class="ml-2">Icon</span>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-2 text-right">
              <label class="mt-2"><strong>Description : </strong></label>
            </div>
            <div class="col-md-7">
                      </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-2 text-right">
              <label class="mt-2"><strong>Description(Arabic) : </strong></label>
            </div>
            <div class="col-md-7">
                         </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-2 text-right">
              <label class="mt-2"><strong>Allow Review<span class="error-block">* </span> :</strong></label>
            </div>
            <div class="col-md-7">
              <label class="switch ">
                <input type="checkbox" class="success" name="isReviewAllow" #isReviewAllow="ngModel"
                  [(ngModel)]="category.isReviewAllow">
                <span class="slider round"></span>
              </label>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-2 text-right">
              <label class="mt-2"><strong>Publish : </strong></label>
            </div>
            <div class="col-md-7">
              <label class="switch ">
                <input autocomplete="off" type="checkbox" class="success" name="status" #status="ngModel"
                  [(ngModel)]="category.publish">
                <span class="slider round"></span>
              </label>
            </div>
          </div>
          <hr>
          <div class="row text-right">
            <div class="col-md-12">
              <button type="submit" class="btn btn-primary mr-2">Save</button>
              <button type="button" class="btn btn-primary"
                [routerLink]="categoryType === categoryConstants.Category ? [URLConstants.CATEGORY] : (categoryType === categoryConstants.SubCategory ? [URLConstants.SUB_CATEGORY, localStorageService.getToken('parentCategoryId')] : [URLConstants.SUB_SUB_CATEGORY, localStorageService.getToken('parentSubCategoryId')])">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>