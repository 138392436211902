import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CommonService } from '../../../common/service/services/common.service';
import { callAPIConstants } from '../../../common/constants/callAPI-constants';
import { ShowErrorService } from '../../../common/service/services/show-error.service';
import { Router } from '@angular/router';
// constants

@Component({
  selector: 'app-cropper-modal',
  templateUrl: './cropper-modal.component.html',
  styles: [
  ]
})
export class CropperModalComponent implements OnInit {
  public callAPIConstants = callAPIConstants;
  @Input() imageChangedEvent: any;
  @Input() modalRef: any;
  @Input() redirectFrom: any;
  @Output() getCroppedImage = new EventEmitter();
  constructor(public router: Router,public modalService: BsModalService,public BsModalRef : BsModalRef, public commonService : CommonService,public showErrorService: ShowErrorService) {  
  }

  ngOnInit() {}


  /****************************************************************************
  @Purpose     : Uploading Image
  @Parameter   : $event
  @Return      : NA
  /****************************************************************************/
  public file: any;
  croppedImage = '';
  public sliceSize: any;
  public imageURL = '';

  imageCropped(event) {
    this.croppedImage = event.base64;

    const ImageURL = event.base64;
    // Split the base64 string in data and contentType
    const block = ImageURL.split(';');
    // Get the content type of the image
    const contentType = block[0].split(':')[1]; // In this case "image/gif"
    // get the real base64 content of the file
    const realData = block[1].split(',')[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."

    // Convert it to a blob to upload
    this.b64toBlob(realData, contentType, this.sliceSize);

  }

  imageLoaded() { /* show cropper */ }

  loadImageFailed() { /* show message */ }


  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    this.file = new File([blob], 'uploaded_file.jpg', { type: contentType, lastModified: Date.now() });
  }

  submitcroppedImage() {
    this.getCroppedImage.emit(this.file);
  }

  /****************************************************************************/
}
