import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// routing
import { AppRoutingComponents, AppRoutingModule } from './app-routing.module';

// shared
import { SharedModule } from './shared/shared/shared.module';

// Common
import { ServiceModule } from './common/service/service.module';
import { CommonService } from './common/service/services/common.service';
import { CanAuthActivate, CanLoginActivate } from './common/service/TS-files/auth.gaurd';
import { ErrorMessages } from './common/service/TS-files/errorMessages';

// plugins
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SocialLoginModule, AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

// component
import { AppComponent } from './app.component';
const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider('501764916621-h9rrpqpfog546ig386fmflqab8ro9im9.apps.googleusercontent.com')
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('480331419850345')
  },
  // {
  //   id: LinkedInLoginProvider.PROVIDER_ID,
  //   provider: new LinkedInLoginProvider('78iqy5cu2e1fgr')
  // }
]);

export function provideConfig() {
  return config;
}
@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    AppRoutingComponents,
  ],
  imports: [
    SharedModule,
    ModalModule.forRoot(),
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ServiceModule,
   SocialLoginModule,
   NgxIntlTelInputModule
  ],
  exports: [
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    CanLoginActivate,
    CanAuthActivate,
    CommonService,
    BsDropdownDirective,
    ErrorMessages,
    BsModalRef,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    }
  ],
})
export class AppModule { }
