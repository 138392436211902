<!-- SAVE ASSIGN DRIVER MODAL -->
<div class="modal-header">
    <h4 class="modal-title pull-left">{{ action == 'change' ? 'Change Driver' : 'Assign Driver' }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form #assignDriverForm="ngForm" (ngSubmit)="assignDriver(assignDriverForm, driverData)">
        <div *ngIf="driverList.length" class="form-group" [ngClass]="((submitted || driverId.touched) && driverId.invalid)?'has-danger':''">
            <ng-select
                class="ng-select-line"
                [ngClass]="((submitted || driverId.touched) && driverId.invalid)?'has-danger':''"
                [items]='driverList'
                bindLabel="driverName"
                bindValue="_id"
                placeholder="Select Driver"
                [clearable]="false"
                [searchable]="false"
                autocomplete="false"
                [dropdownPosition]="'auto'"
                name="driverId"
                #driverId="ngModel"
                [(ngModel)]="driverData.driverId"
                required
            >
            </ng-select>
            <div class="error-block text-left" *ngIf="((submitted || driverId.touched) && driverId.invalid)">
                {{showErrorService.showError('driverId', driverId.errors) }}
            </div>
        </div>
        <div class="text-right">
            <button type="submit" class="btn btn-primary">Submit</button>
        </div>
    </form>
</div>
<!-- SAVE ASSIGN DRIVER MODAL -->
    
    