
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// constants
import { callAPIConstants } from '../../common/constants/callAPI-constants';
import { URLConstants } from '../../common/constants/routerLink-constants';
//Service
import { CommonService } from '../../common/service/services/common.service';
import { ShowErrorService } from '../../common/service/services/show-error.service';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styles: [],
})

export class ForgotPasswordComponent implements OnInit {

  callAPIConstants = callAPIConstants
  URLConstants = URLConstants;
  constructor(public commonService: CommonService,public showErrorService: ShowErrorService,public router: Router ) {
  }

  ngOnInit() {
  }

  /*************************************************************
	@Purpose     : Forgot password
	@Parameter   : NA
	@Return      : url
	/*************************************************************/
  public user: any = {};
  public submitted = false;
  onSubmit(form, user) {
    this.submitted = true;
    if (form.valid) {
      this.commonService.callApi(this.callAPIConstants.AdminForgotPassword, user, 'post', true, false).then((success) => {
        if (success.status === 1) {
          this.showErrorService.popToast('success', success.message);
          this.router.navigate([this.URLConstants.LOGIN]);
        } else {
          this.showErrorService.popToast('error', success.message);
        }
      });
    }
  }
  /*************************************************************/
}
