import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// pipes
import { ShortNumberPipe } from './short-number.component';

@NgModule({
    declarations: [ShortNumberPipe],
    imports: [
        CommonModule,
    ],
    providers: [],
    exports: [ShortNumberPipe],
})
export class PipeModule { }
