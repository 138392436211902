import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// service
import { CommonService } from './common.service';
import { LocalStorageService } from './local-storage.service';

// constants
import { URLConstants } from '../../constants/routerLink-constants';

@Injectable({
  providedIn: 'root',
})
export class GetPermissionsService {
  URLConstants = URLConstants;

  constructor(private localStorageService: LocalStorageService, private commonService: CommonService, private router: Router) { }

  /****************************************************************************
  @PURPOSE      : Get Pemission according to Role
  @PARAMETERS   : key
  @RETURN       : roleKey
  /****************************************************************************/
  getRolePermissions(key) {
    let role;
    if (this.localStorageService.getToken('role_permission')) {
      role = this.commonService.decrypt(this.localStorageService.getToken('role_permission'));
      let roleKey: any;
      switch (key) {
        case 'user':
          {
            roleKey = role.userAccess;
          }
          break;
        case 'adminUser':
          {
            roleKey = role.adminUserAccess;
          }
          break;
        case 'vendor':
          {
            roleKey = role.vendorAccess;
          }
          break;
        case 'driver':
          {
            roleKey = role.driverAccess
              ;
          }
          break;
        case 'category':
          {
            roleKey = role.categoryAccess
              ;
          }
          break;
        case 'orders':
          {
            roleKey = role.ordersAccess
              ;
          }
          break;
        case 'products':
          {
            roleKey = role.productAccess
              ;
          }
          break;
        case 'brands':
          {
            roleKey = role.brandAccess
              ;
          }
          break;
      }
      return roleKey;
    }
  }
  /****************************************************************************/

  /****************************************************************************
  @PURPOSE      : Hide Action In Responsive Data Table
  @PARAMETERS   : tempData
  @RETURN       : NA
  /****************************************************************************/
  hideActionInTable(tempData) {
    const index = tempData.findIndex((o) => o.type === 'action');
    return tempData[index].isVisible = false;
  }
  /****************************************************************************/

  /****************************************************************************
  @PURPOSE      : Common function for AUTH GUARD
                  (canActivate gives value False and Routes to Dashboard)
  @PARAMETERS   : condition
  @RETURN       : Boolean
  /****************************************************************************/
  CanActivateFalseRouteDashboard(condition) {
    if (condition) {
      return true;
    }
    this.router.navigate([this.URLConstants.DASHBOARD]);
    return false;
  }
  /****************************************************************************/
}
