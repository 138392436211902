export const categoryConstants = {
    Category: 'category',
    SubCategory: 'subCategory1',
    SubSubCategory: 'subCategory2',
};

export const adminRoleConstants = {
    superAdmin: 'Super Admin',
    vendor: 'Vendor',
};

export const defaultPermissionConstants = {
    create: true,
    delete: true,
    edit: true,
    view: true,
};

export const languageType = [
    {'_id': 'en', 'value': 'English'},
    {'_id': 'ar', 'value':'Arabic'}
];

export const discountType = [
    {'_id': 'FLAT', 'value': 'FLAT'},
    {'_id': 'Percentage', 'value':'Percentage'}
];

export const discountTypeArabic = [
    {'_id': 'FLAT', 'value': 'مسطحة'},
    {'_id': 'Percentage', 'value':'النسبة المئوية'}
];

export const viewPosition = [
    {'_id': 'top', 'value': 'Top'},
    {'_id': 'bottom', 'value':'Bottom'}
];

export const vehicleType = [
    {'_id': 'Bike', 'value':'Bike'},
    {'_id': 'Car', 'value': 'Car'},
    {'_id': '3-Wheeler', 'value':'3-Wheeler'}
];

export const promocodeType = [
    {'_id': 'single', 'value': 'Single'},
    {'_id': 'multiple', 'value':'Multiple'}
];



export const MAX_RATING = 5;
export const DEFAULT_LANGUAGE = 'en';