import { Component, DoCheck, Input, OnDestroy, OnInit } from '@angular/core';

// environment
import { environment } from 'src/environments/environment';

// plugins
import * as grapesjs from 'grapesjs';
import 'grapesjs-preset-newsletter';
import 'grapesjs-preset-webpage';

// constants
import { callAPIConstants } from '../../../common/constants/callAPI-constants';

// Services
import { CommonService } from '../../../common/service/services/common.service';
import { LocalStorageService } from '../../../common/service/services/local-storage.service';

@Component({
  selector: 'app-grapes-js',
  templateUrl: './grapes-js.component.html',
  styles: [],
})
export class GrapesJSComponent implements OnInit, OnDestroy, DoCheck {
  callAPIConstants = callAPIConstants;
  public _editor: any;
  public emailTemplateId: any;
  public blocks: any;
  public fieldList: any;
  public showRichTextEditor: boolean;
  @Input() emailTemplate: any;
  @Input() type: any;
  public plugin: any;
  public pluginsOpts: any;
  public panelManager: any;
  public colorPicker1: any;

  constructor(private commonService: CommonService, private localStorageService: LocalStorageService) {
     this.getEmailTemplatesKeys();
  }


  ngOnInit() {
    setTimeout(() => {
      if (this.emailTemplate && this.type) {

        if (this.type === 'emailDetail') {
          this.showRichTextEditor = true;
          this.blocks = ['default', 'Extra', 'forms', 'link-block', 'quote', 'image', 'video', 'text', 'column1', 'column2', 'column3'];
          this.plugin = ['grapesjs-preset-newsletter'];

          // this.pluginsOpts = 'gjs-preset-webpage';
        } else {
          this.showRichTextEditor = false;
          this.blocks = ['image', 'text'];
          this.plugin = ['grapesjs-preset-webpage'];
        }

        this._editor = this.initializeEditor();
        if (this._editor.RichTextEditor.get('insert')) { this._editor.RichTextEditor.remove('insert'); }
        if (this.showRichTextEditor) {
          /*************************************************************
          @Purpose     : Add Custom Richtexteditor
          /*************************************************************/
          const getNgSelect = document.getElementById('dynamic');
          if (this._editor.RichTextEditor.get('insert')) { this._editor.RichTextEditor.remove('insert'); }
          this._editor.RichTextEditor.add('insert', {
            icon: getNgSelect,
            result: (rte, action) => {
              const data = rte.el.firstChild.ownerDocument;
              if (this.insertValue) {
                data.execCommand('insertText', false, '{{' + this.insertValue + '}}');
                this.insertValue = '';
              }
            },
          });
          /*************************************************************/
        }

        /******************************************************************
        @Purpose     : Add Custom Panel for undo and delete in
                       gjs-preset-newsletter as not provided by plugin
        /*****************************************************************/
        if (this.type === 'emailDetail') {
          this.panelManager = this._editor.Panels;
          this.panelManager.addButton('options', {
            id: 'undo',
            className: 'fa fa-undo',
            command: (e) => e.runCommand('core:undo'),
            attributes: { title: 'undo' },
            active: true,
          });
          this.panelManager.addButton('options', {
            id: 'redo',
            className: 'fa fa-repeat',
            command: (e) => e.runCommand('core:redo'),
            attributes: { title: 'redo' },
            active: true,
          });
          this.panelManager.addButton('options', {
            id: 'delete',
            className: 'fa fa-trash',
            command: (e) => {
              const res = window.confirm('Are you sure want to clear the canvas?');
              if (res) {
                e.runCommand('core:canvas-clear');
              }
            },
            attributes: { title: 'Delete' },
            active: true,
          });
          this.panelManager.addButton('options', {
            id: 'preview',
            className: 'fa fa-eye',
            command: (e) => e.runCommand('preview'),
            attributes: { title: 'preview' },
            active: true,
          });
          this.panelManager.removeButton('options', 'gjs-toggle-images');
        }
        /*******************************************************************************************/
      }
    }, 1000);
  }

  ngDoCheck() {
    if (this._editor && this.type === 'emailDetail') {
      this.localStorageService.setToken('gjs-inlined-html', this._editor.runCommand('gjs-get-inlined-html'));
    }
  }

  ngOnDestroy() {
    // destroy instance of editor
    if (this._editor) { this._editor.destroy(); }
  }

  /*************************************************************
  @Purpose     : Get Email Template Details
  @Parameter   : NA
  @Return      : emailTemplate
  /*************************************************************/
  public getEmailTemplatesKeys() {
    this.commonService.callApi(this.callAPIConstants.emailTemplatesKeys,'', 'post', false, false)
      .then((success) => {
        this.fieldList = success.data[0];
      });
  }
  /*************************************************************/

  /*************************************************************
    @Purpose     : nitialize Grapesjs
    @Parameter   : NA
    @Return      : NA
  /*************************************************************/
  private initializeEditor() {
    return grapesjs.init({
      container: '#gjs',
      height: '700px',
      fromElement: false,
      autorender: true,
      forceClass: (this.plugin == ['grapesjs-preset-newsletter']) ? false : true,
      components: this.localStorageService.getToken('gjs-inlined-html') ? this.localStorageService.getToken('gjs-inlined-html')
        : (this.emailTemplate.gjsHtml ? this.emailTemplate.gjsHtml : ''),
      style: this.emailTemplate.gjsCss ? this.emailTemplate.gjsCss : '',
      colorPicker: { appendTo: 'parent', offset: { top: 26, left: -155 } },
      plugins: this.plugin,
      // plugins: ['grapesjs-preset-webpage'],
      pluginsOpts: {
        [this.plugin]: {
          /* gjs-preset-newsletter options */
          inlineCss: true,
          modalLabelImport: 'Paste all your code here below and click import',
          modalLabelExport: 'Copy the code and use it wherever you want',
          codeViewerTheme: 'material',
          /* gjs-preset-newsletter options */
          /* grapesjs-preset-webpage option */
          modalImportLabel: 'Paste all your code here below and click import',
          modalImportTitle: 'Import template',
          modalExportLabel: 'Copy the code and use it wherever you want',
          /* grapesjs-preset-webpage option */
          navbarOpts: false,
          countdownOpts: false,
          formsOpts: false,
          blocksBasicOpts: {
            blocks: this.blocks,
            flexGrid: false,
            stylePrefix: 'lala-',
          },
        },
      },
      assetManager: {
        assest: [],
        uploadText: 'Add image through link or upload image',
        modalTitle: 'Select Image',
        inputPlaceholder: 'http://url/to/the/image.jpg',
        addBtnText: 'Add image',
        uploadFile: (e) => {
          const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
          const fileUpload = new FormData();
          fileUpload.append('file', file);
          this.commonService.callApi(this.callAPIConstants.AdminFileUpload, fileUpload, 'post', false, true).then((success) => {
            if (success.status === 1) {
              this._editor.AssetManager.add(environment.uploadImgUrl + success.data.filePath);
            }
          });
        },

      },
      storageManager: {
        autosave: true,
        setStepsBeforeSave: 1,
        autoload: true,
        type: 'local',
        urlStore: '',
        urlLoad: '',
        contentTypeJson: true,
      },
      handleAdd: (textFromInput) => {
        this._editor.AssetManager.add(textFromInput);
      },
      canvas: {
        styles: [
          'https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css',
          'https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css',
        ],
        scripts: ['https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js'],
      },
    });
  }
  /*************************************************************/

  /*************************************************************
   @Purpose     : get value to insert in editor
   @Parameter   : NA
   @Return      : NA
  /*************************************************************/
  public insertValue: any;
  showValue(event) {
    this.insertValue = event;
  }
  /*************************************************************/
}
