<!--BEGIN: Subheader -->

<div class='page-header'>
  <div class='row'>
    <div class='col-md-12'>
      <h3>{{subHeaderData?.title}}</h3>
      <div class="breadcrumb-blog justify-content-sm-between">         
        <!-- BEGIN: Breadcrumbs -->
        <ul class="breadcrumb">
          <li *ngFor="let breadcrumb of subHeaderData?.breadcrumbs" class="breadcrumb-item">
            <a *ngIf="breadcrumb.url; else elseBlock" href=""
              [routerLink]="breadcrumb.id ? [breadcrumb.url, breadcrumb.id] : [breadcrumb.url]">
              <em class="fa fa-home" *ngIf="breadcrumb?.localeKey === 'home'"></em>
              <span *ngIf="breadcrumb?.localeKey !== 'home'">{{breadcrumb?.localeKey}}</span>
            </a>
            <ng-template #elseBlock>
              <a class="active" aria-current="page" href="javascript:Void();">{{breadcrumb?.localeKey}}</a>
            </ng-template>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- END: Subheader -->