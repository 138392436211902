import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// plugins

// environment

// constants
import { callAPIConstants } from '../../common/constants/callAPI-constants';
import { URLConstants } from '../../common/constants/routerLink-constants';

// service
import { CommonService } from '../../common/service/services/common.service';
import { LocalStorageService } from '../../common/service/services/local-storage.service';
import { ShowErrorService } from '../../common/service/services/show-error.service';
import { RestrictKeyPressService } from 'src/app/common/service/services/restrict-key-press.service';
import { LogoutService } from 'src/app/common/service/services/logout.service';
import { SearchCountryField, TooltipLabel, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styles: [],
})
export class SignupComponent implements OnInit {
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  public user: any = {};
  public submitted = false;
  vender: boolean = true;
  gst_details = false;
  bank_info = false;
  set_pwd = false;
  password = false;
  vendorDetails: any = {};
  orderStatusDetails: any = {};
public bankAccountTypeList:any=['Savings','Current']
  signupPage: boolean;
  SearchCountryField = SearchCountryField;
	TooltipLabel = TooltipLabel;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  constructor(private commonService: CommonService,
    public showErrorService: ShowErrorService,
    public restrictKeyPressService: RestrictKeyPressService,
    private localStorageService: LocalStorageService,
    private logoutService:LogoutService,
    private router: Router) {
  }

  ngOnInit() {
    this.signupPage=this.router.url.includes('signup');
    if(this.localStorageService.getToken('vendorDetails')){
      this.vendorDetails=JSON.parse(this.localStorageService.getToken('vendorDetails'));
    }
    else{
      this.vendorDetails.fullName=this.localStorageService.getToken('firstname');
    }
    if(this.localStorageService.getToken('socialLogin')){
      this.vendorDetails.emailId=this.localStorageService.getToken('socialEmailId');
    }
  }

  /*************************************************************/
  next(parms) {
    if (parms == 'vender') {
      this.vender = false;
      this.gst_details = true;
    }
    else if (parms == 'gst_details') {
      this.gst_details = false;
      this.bank_info = true;
    }
    this.localStorageService.setToken('vendorDetails',JSON.stringify(this.vendorDetails));
  }

  cancel(parms) {
 if (parms == 'gst_details') {
      this.gst_details = false;
      this.vender = true;
    }
    else if (parms == 'bank_info') {
      this.bank_info = false;
      this.gst_details = true;
    }
    // else if (parms == 'password') {
    //   this.bank_info = true;
    //   this.password = false;
    // }
  }



  register() {
    this.vendorDetails['countryCode'] = this.vendorDetails.contactNo.dialCode;
    this.vendorDetails['mobileNo'] = this.vendorDetails.contactNo.number;
    this.vendorDetails['countryShortName']=this.vendorDetails.contactNo.countryCode?this.vendorDetails.contactNo.countryCode:this.vendorDetails.countryShortName;
    if(this.signupPage){
      this.commonService.callApi(this.callAPIConstants.VendorRegister, this.vendorDetails, 'post', true).then((res) => {
        if (res.status === 1) {
          this.showErrorService.popToast('success',res.message);
          this.localStorageService.removeToken('vendorDetails');
          this.router.navigate(['/login']);
        } else {
        this.showErrorService.popToast('error',res.message);
        }
      })
    } else {
      this.commonService.callApi(this.callAPIConstants.vendorAddDetails, this.vendorDetails, 'post').then((res) => {
        if (res.status === 1) {
          this.showErrorService.popToast('success',res.message);
          this.localStorageService.setToken('profileCompleted',true);
          this.localStorageService.removeToken('vendorDetails');
          this.router.navigate(['/login']);
        } else {
        this.showErrorService.popToast('error',res.message);
        }
      })
    }
  }
  
  setAddress(event){
    this.vendorDetails.address = event.formatted_address;
    this.vendorDetails.area = event.address;
    this.vendorDetails.city = event.city;
    this.vendorDetails.state = event.state;
    this.vendorDetails.postalCode = event.postalCode;
    this.vendorDetails.country = event.country;
    this.vendorDetails.countryCode = event.countryCode;
    this.vendorDetails.latitude = event.lat;
    this.vendorDetails.longitude = event.lng;
  }

  restrictPaste(event){
    if(event){ return false;}
  }
  headerLogo(){
    if(this.localStorageService.getToken('accessToken')){
			if(this.localStorageService.getToken('profileCompleted') ==='true'){
      this.router.navigate([this.URLConstants.DASHBOARD]);
      } else{this.router.navigate([this.URLConstants.UPDTAEINFORMATION])}
    } else {
      this.router.navigate([this.URLConstants.LOGIN]);
    }
  }
  	/*************************************************************
	 @Purpose     : Perform logout acton
	 @Parameter   : NA
	 @Return      : null
	/*************************************************************/
	logoutUser() {
		this.showErrorService.confirmpopToast('warning', 'Are you sure, you want to logout ?', 'LOGOUT').then((result) => {
          	if (result.value === true) {
				this.logoutService.logout();
          	}
        });
	}
	/****************************************************************************/
}
