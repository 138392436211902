import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/common/service/services/common.service';
import { ShowErrorService } from 'src/app/common/service/services/show-error.service';
// constants
import { callAPIConstants } from '../../common/constants/callAPI-constants';
import { URLConstants } from '../../common/constants/routerLink-constants';
@Component({
  selector: 'app-verify-vendor',
  templateUrl: './verify-vendor.component.html',
  styles: [
  ]
})
export class VerifyVendorComponent implements OnInit {

  public token: any;
  callAPIConstants=callAPIConstants;
  URLConstants=URLConstants;
  message: any;
  constructor(public showErrorService: ShowErrorService, private commonService: CommonService, private router: Router) {
    this.token = this.router.parseUrl(this.router.url).queryParams['token'];
    this.verifyVendor();
  }

  ngOnInit(): void {
  }
  verifyVendor(){
    this.commonService.callApi(`${this.callAPIConstants.verifyVendor}?token=${this.token}`,'', 'get', true, false).then((success) => {
      if (success.status === 1) {
        this.showErrorService.popToast('success', success.message);
        this.message=success.message;
        // this.router.navigate([this.URLConstants.LOGIN]);
      } else {
        this.showErrorService.popToast('error', success.message);
        this.message=success.message;
        this.router.navigate([this.URLConstants.LOGIN]);
      }
    });  }
}
