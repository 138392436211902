<!-- Order basic info section starts -->
<div class="card col-md-12 Address-info">
    <div class="p-0 mb-3">
        <div class="animated fadeIn">
            <div class="card-header p-0 mb-3">
                <h5>Order &amp; Account Information</h5>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6">
                            <h5>Order Information</h5>
                            <div class="order-details-blog">
                                <ul>
                                    <li>
                                        <p><strong><span class="order-date">Order ID: </span></strong><span>{{ orderDetail?.orderId }}</span></p>
                                    </li>
                                    <li>
                                        <p><strong><span class="order-date">Order Date: </span></strong><span>{{ orderDetail?.createdAt | date }}</span></p>
                                    </li>
                                    <li>
                                        <p><strong><span class="order-date">Placed From IP: </span></strong><span>{{ orderDetail?.ipAddress }}</span></p>
                                        
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <h5>Account Information</h5>
                            <div class="order-details-blog">
                                    <ul>
                                        <li>
                                            <p><strong><span class="order-date">Customer Name: </span></strong><span>{{ orderDetail?.buyerName }}</span></p>
                                        </li>
                                        <li>
                                            <p><strong><span class="order-date">Email: </span></strong><span>{{ orderDetail?.emailId }}<a href="mailto:{{ orderDetail?.emailId }}"></a></span></p>
                                        </li>
                                    </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Order basic info section ends -->

<!-- Order status section starts -->
<div class="card col-md-12 Address-info" *ngIf="redirectFrom === 'infoTab'">
    <div class="p-0">
        <div class="animated fadeIn">
            <div class="card-header p-0 mb-3">
                <h5>Order Status</h5>
            </div>
            <div class="mx-auto">
                    <div class="row justify-content-between top progressbar-text">
                            <div class="row d-flex icon-content">
                                <div class="d-flex flex-column">
                                    <p class="font-weight-bold"><em class="fa fa-file-text-o" aria-hidden="true"></em>Processing</p>
                                </div>
                            </div>
                            <div class="row d-flex icon-content">
                                <div class="d-flex flex-column">
                                    <p class="font-weight-bold"><em class="fa fa-cog" aria-hidden="true"></em>Dispatched</p>
                                </div>
                            </div>
                            <div class="row d-flex icon-content">
                                <div class="d-flex flex-column">
                                    <p class="font-weight-bold"><em class="fa fa-truck" aria-hidden="true"></em>Out For Delivery</p>
                                </div>
                            </div>
                            <div class="row d-flex icon-content">
                                <div class="d-flex flex-column">
                                    <p class="font-weight-bold"><em class="fa fa-home" aria-hidden="true"></em>Delivered</p>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-center">
                            <div class="col-12">
                                <ul id="progressbar" class="text-center">
                                    <li [ngClass]="orderDetail.orderStatus =='delivered' ||orderDetail.orderStatus =='dispatched' ||orderDetail.orderStatus =='processing'|| orderDetail.orderStatus =='outForDelivery' ? 'active step0' : 'step0' "></li>
                                    <li [ngClass]="orderDetail.orderStatus =='delivered' ||orderDetail.orderStatus =='dispatched' || orderDetail.orderStatus =='outForDelivery' ? 'active step0' : 'step0' "></li>
                                    <li [ngClass]="orderDetail.orderStatus =='delivered'  || orderDetail.orderStatus =='outForDelivery'? 'active step0' : 'step0' "></li>
                                    <li [ngClass]="orderDetail.orderStatus =='delivered' ? 'active step0' : 'step0' "></li>
                                </ul>
                            </div>
                        </div>
            </div>
        </div>
    </div>
</div>
<!-- Order status section ends -->

<!-- Order address section starts -->
<div class="card col-md-12 Address-info">
    <div class="animated fadeIn">
            <div class="card-header p-0 mb-3">
                <h5>Address Information</h5>
            </div>
            <div class="row info_order_address">
                <div class="col-md-6" *ngIf="orderDetail?.billingAddress != 'undefined' && orderDetail?.billingAddress != ''">
                    <h5>Billing Address</h5>
                    <br><p><strong>{{ orderDetail?.billingAddress?.name }}</strong></p>
                    <p>{{ orderDetail?.billingAddress?.addressLine1+', '+orderDetail?.billingAddress?.addressLine2+', ' +orderDetail?.billingAddress?.city+', '+orderDetail?.billingAddress?.state+' - '+orderDetail?.billingAddress?.zipCode }}</p>
                    <p><em class="strong fa-phone" aria-hidden="true"></em> {{ orderDetail?.billingAddress?.mobileNo }}</p>
                </div>
                <div class="col-md-6" *ngIf="orderDetail?.shippingAddress != 'undefined' && orderDetail?.shippingAddress != ''">
                    <h5>Shipping Address</h5>
                    <br><p><strong>{{ orderDetail?.shippingAddress?.name }}</strong></p>
                    <p>{{ orderDetail?.shippingAddress?.addressLine1+', '+orderDetail?.shippingAddress?.addressLine2+', ' +orderDetail?.shippingAddress?.city+', '+orderDetail?.shippingAddress?.state+' - '+orderDetail?.shippingAddress?.zipCode }}</p>
                    <p><em class="fa fa-phone" aria-hidden="true"></em> {{ orderDetail?.shippingAddress?.mobileNo }}</p>
                </div>
            </div>

            <ng-container *ngIf="redirectFrom === 'returnTab'">
                <div class="card-header p-0 mb-3">
                    <h5>Driver Information</h5>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <agm-map [latitude]="lat" [longitude]="lng" style="height: 500px;">
                            <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
                        </agm-map>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 mb-3 mt-3">
                        <ng-container *ngIf="orderDetail?.driver;else assignDriverCon">
                            Driver : {{ orderDetail?.driver }}
                            <button type="button" class="btn btn-primary" (click)="openModal(assignDriver)" style="vertical-align: inherit;" tabindex="0">Change Driver</button>
                        </ng-container>
                        <ng-template #assignDriverCon>
                            <button type="button" class="btn btn-primary" (click)="openModal(assignDriver)" style="vertical-align: inherit;" tabindex="0">Assign Driver</button>
                        </ng-template>
                    </div>
                </div>
            </ng-container>
    </div>
</div>
<div class="card col-md-12 Address-info" *ngIf="orderDetail?.paymentType != 'COD'">
    <div class="p-0 mb-3">
        <div class="row">
            <div class="col-md-12">
                <div class="card-header p-0">
                    <h5>Payment & Shipping Information</h5>
                </div>
                <table class="table table-striped table-hover" aria-describedby="desc">
                    <thead>
                        <tr>
                            <th scope="col">Transaction ID</th>
                            <th scope="col">Transaction Date</th>
                            <th scope="col">Amount</th>
                            <!-- <th scope="col">Transaction Status</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">{{ orderDetail?.razorpayPaymentId }}</th>
                            <td>{{ orderDetail?.createdAt | date }}</td>
                            <td>{{ orderDetail?.totalAmount | currency : globalSettings.currency }}</td>
                            <!-- <td>{{ orderDetail?.status }}</td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>


<div class="card col-md-12 Address-info">
        <div class="row">
            <div class="col-md-12 item-order">
                <div class="mb-3">
                    <div class="card-header p-0">
                        <h5>Items Ordered</h5>
                    </div>
                    <ng-container *ngIf="orderedProducts && orderedProducts.length">
                        <table class="table table-striped table-hover" aria-describedby="desc">
                            <thead>
                                <tr>
                                    <th scope="col">Product Name</th>
                                    <th scope="col">SKU</th>
                                    <th scope="col">Unit Price</th>
                                    <th scope="col">Order Quantity</th>
                                    <th scope="col" *ngIf="orderData?.confirmOrder">Processing Quantity</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Sub Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of orderedProducts;let i=index">
                                    <td>{{ item?.productName }}</td>
                                    <th id="th">{{ item?.SKU }}</th>
                                    <td>{{ item?.salePrice | currency : globalSettings.currency }}</td>
                                    <td *ngIf="redirectFrom === 'infoTab';else quantityData">
                                        Ordered : {{ item?.ordered }}<br />
                                        <ng-container *ngIf="item.delivered > 0">Shipped : {{ item?.delivered }}<br /></ng-container>
                                        <ng-container *ngIf="item.returned > 0">Return : {{ item?.returned }}<br /></ng-container>
                                        <ng-container *ngIf="item.cancelled > 0">Cancelled : {{ item?.cancelled }}<br></ng-container>
                                        <ng-container *ngIf="item.pending > 0">Pending : {{ item?.pending }}</ng-container>
                                    </td>
                                    <ng-template #quantityData>
                                        <td>{{ item?.quantity }}</td>
                                    </ng-template>
                                    <td *ngIf="orderData?.confirmOrder">
                                    {{productsArray[i]['quantity'] ? productsArray[i]['quantity'] : item?.ordered}}
                                    </td>
                                    <td>
                                        <div class="w-100" 
                                        >
                                        <ng-select class="ng-select-line" (change)="readyToShip(item)"
                                        [items]="statusList" bindLabel="name" bindValue="value" placeholder="Select Status" [clearable]='false' [searchable]='true' autocomplete="false" [dropdownPosition]="'auto'" name="type" #type="ngModel" [(ngModel)]="item.vendorOrderProductStatus" required>
                                    </ng-select>
                                     </div></td>
                                    <td>{{ item?.subTotal | currency : globalSettings.currency }}</td>
                                </tr>
                            </tbody>
                        </table>
                        
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="card-header p-0 mb-3">
            <h5>Order Total</h5>
        </div>
        <div class="row">
            <div class="col-md-6">
                <form #form="ngForm" (ngSubmit)="saveComment(form,admin)" novalidate="" class="ng-untouched ng-pristine ng-valid pb-4">
                    <label class="d-block">
                        <h5 class="pull-left">Notes for this Order</h5>
                    </label>
                    <div class="form-group">
                        <textarea placeholder="Comment" class="form-control ng-untouched ng-pristine ng-valid" name="comment" #comment="ngModel" [(ngModel)]="admin.comment" required></textarea>
                        <div class="error-block text-left" *ngIf="((submitted || comment.touched) && comment.invalid)">
                            {{showErrorService.showError('comment', comment.errors)}}
                        </div>
                    </div>
                    <label>
                        <input name="emailToCustomers" ng-checked="{{admin.emailToCustomers}" type="checkbox" ng-reflect-model="true" class="ng-untouched ng-pristine ng-valid" /><em class="input-helper"></em> Notify Customer by Email
                    </label>
                    <br />
                    <button class="btn btn-primary mt-2" type="submit">Submit Comment</button>
                </form>
            </div>

            <div class="col-md-6">
                <label><h5>Order Totals</h5></label>
                <table class="table dataTable with-image row-border hover custom-table pb-4" aria-describedby="desc">
                    <thead class="d-none">
                        <tr>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="animated fadeIn">
                            <td>Subtotal</td>
                            <td class="text-right">{{ orderTotals?.subTotal | currency : globalSettings.currency }}</td>
                        </tr>
                        <tr class="animated fadeIn">
                            <td>Shipping Cost</td>
                            <td class="text-right">{{ orderTotals?.shippingCost | currency : globalSettings.currency }}</td>
                        </tr>
                        <tr class="animated fadeIn">
                            <td>Tax</td>
                            <td class="text-right">{{ orderTotals?.taxAmount | currency : globalSettings.currency }}</td>
                        </tr>
                        <tr class="animated fadeIn">
                            <td><strong>Grand Total</strong></td>
                            <td class="text-right"><strong>{{ orderTotals?.grandTotal | currency : globalSettings.currency }}</strong></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
</div>
    
<!-- ASSIGN DRIVER MODAL -->
<ng-template #assignDriver>
  <app-assign-driver action="assign"></app-assign-driver>
</ng-template>
<!-- ASSIGN DRIVER MODAL -->

<!-- CHANGE DRIVER MODAL -->
<ng-template #changeDriver>
  <app-assign-driver action="change" [shipmentId]="orderDetail?.shipmentId"></app-assign-driver>
</ng-template>
<!-- CHANGE DRIVER MODAL -->    