import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styles: [],
})
export class SubHeaderComponent implements OnInit {
  @Input() subHeaderData: any;

  constructor() { }

  ngOnInit() { }
}
