import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styles: [],
})
export class MainComponent implements OnInit {

  public currentYear: any = new Date().getFullYear();
  Verticalscrollbar: any = {};

  constructor() { }

  ngOnInit() {
  }

  overlayHide() {
    $('body').removeClass('asideleft-on');
    $('body').removeClass('asideleft-minimize');
  }
}
