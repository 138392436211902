<!-- BEGIN : Show Skeleton Loader -->
<div class="content-wrapper" [hidden]="!showSkeletonLoader">
  <div class="card animated fadeIn m-0">
    <div class="card-header">
      <div class="row">
        <div class="col-md-6"></div>
        <div class="col-md-6">
          <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <div class="card-body">
      <ngx-skeleton-loader count="5"></ngx-skeleton-loader>
    </div>
    <div class="card-footer">
      <div class="row">
        <div class="col-md-6"></div>
        <div class="col-md-6">
          <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div> 
</div>
<!-- END : Show Skeleton Loader -->

<div
  [ngClass]="router.url.includes(URLConstants.PRODUCTS_DETAIL) || router.url.includes(URLConstants.DASHBOARD) || router.url.includes(URLConstants.GENERAL) || router.url.includes(URLConstants.USERS_DETAIL) ? '' : 'content-wrapper'"
  [hidden]="showSkeletonLoader">
  <div class="card p-3 animated fadeIn border-0 m-0"
    [ngClass]="router.url.includes(URLConstants.PRODUCTS_DETAIL) || router.url.includes(URLConstants.DASHBOARD) || router.url.includes(URLConstants.USERS_DETAIL) ? 'mb-0' : ''">
    <!-- TABLE HEADER START-->
    <div *ngIf="this.conditions.showTableHeader" class="card-header border-0 p-0">
       <div class="data-filter">
      <div class="d-flex justify-content-between row">
        <div class="d-flex left-div-filter col-lg-6 sm-mb-20">
          
          <div *ngIf="this.conditions.showApplyStatus && this.selectedUserList.length" [ngClass]="(type == 'productList' || type == 'brandlist' || type == 'categorylist' || type == 'subCategorylist' || type == 'subSubCategorylist' || type == 'vendorProductList') ? 'mr-3 w-150' :  'mr-3 w-150'">
            <ng-container>
              <!-- apply active , inactive and delete on mutiple users -->
              <form #statusForm="ngForm" (ngSubmit)="submitStatusForm(statusForm,statusFormData)" class="w-150">
                <ng-select class="ng-select-line select-option" [items]="statusList" bindLabel="statusFormData?.status"
                  bindValue="statusFormData.status" placeholder="Select" [clearable]='false' [searchable]='false'
                  [dropdownPosition]="'auto'" #status="ngModel" name="status" [(ngModel)]="statusFormData.status" required
                  (change)="submitStatusForm(statusForm,statusFormData)">
                </ng-select>
              </form>
              <!-- apply active , inactive and delete on mutiple users -->
            </ng-container>
          </div>
         <!-- filter -->
         <ng-container *ngIf="this.conditions.showFilter">
           <div class="mr-3 w-150 xs-width-auto">
          <button class="nav-link pull-right btn btn-teal filterlink w-150" type="button" data-toggle="collapse" 
            data-target="#filterColumn" aria-expanded="false" aria-controls="filterColumn"
            (click)="toggleFilterColumn()" tooltip="Filter" placement="bottom" [disabled]="!tableData?.data?.length">
            <em class="fa fa-filter mr-0" aria-hidden="true"><span>Filter Data</span></em>
          </button>
        </div>
        </ng-container>
        <!-- filter -->     

        <!-- search -->
        <ng-container *ngIf="conditions?.search">
          <form #statusForm="ngForm" autocomplete="off" class="search-box">
            <div class="form-group mr-3 w-150 xs-width-auto" >
              <input type="text" class="form-control" name="searchKey" typeaheadOptionField="searchKey"
                [typeaheadMinLength]="0" (focus)="typeaheadOnBlur($event)" autocomplete="off" (blur)="typeaheadOnBlur($event)"
                placeholder="Search" typeaheadWaitMs="600" [(ngModel)]="searchKey" [typeahead]="MainSearchdataSource">
            </div>
          </form>
        </ng-container>
        <!-- search -->



        <!-- column hide show-->
        <ng-container *ngIf="this.conditions.showColumnHide && !showGridLayout">
          <div class="mr-3 w-150 xs-width-auto xs-mr-0">
          <button type="button" class="nav-link  btn btn-teal listlink" data-toggle="collapse"
            data-target="#columnVisiblityFilter" aria-expanded="false" aria-controls="columnVisiblityFilter"
            [attr.aria-expanded]="!columnVisibilityOptions" (click)="tooglecolumnVisibilityFilter()"
            tooltip="Column Options" placement="bottom">
            <em class="fa fa-cog mr-0 font-size-18" aria-hidden="true"></em>
          </button>
        </div>
        </ng-container>
        <!-- column hide show-->


        </div>
        <div class="right-div-filter d-flex col-lg-6 justify-content-end sm-mb-20">
          
          <div class="button-continer text-right mr-3">

        <!-- Add Country Button -->
        <ng-container *ngIf="this.conditions.showAddCountry ">
          <button type="button" (click)="openMasterModal($event,'add')" class="nav-link  btn btn-primary listlink">
            <em class="fa fa-plus" aria-hidden="true"></em>
            <span>Add Country</span>
          </button>
        </ng-container>
        <!-- Add country Button --> 


        <!-- Add Timezone start-->
        <ng-container *ngIf="this.conditions.showAddTimezone ">
          <button type="button" (click)="openMasterModal($event, 'add')" class="nav-link  btn btn-primary listlink w-100">
            <em class="fa fa-plus" aria-hidden="true"></em>
            <span>Add Timezone</span></button>
        </ng-container>
        <!-- Add Timezone End-->


         <!-- Add Currency start-->
         <ng-container *ngIf="this.conditions.showAddCurrency ">
          <button type="button" (click)="openMasterModal($event, 'add')" class="nav-link  btn btn-primary listlink w-100">
            <em class="fa fa-plus" aria-hidden="true"></em>
            <span>Add Currency</span></button>
        </ng-container>
        <!-- Add Currency End-->

            <!-- show button -->
            <ng-container *ngIf="this.conditions.showButton ">
              <ng-container *ngIf="type == 'measurementsList' || type == 'faqList';else basicAdd">
                <button type="button" class="nav-link  btn btn-primary listlink" (click)="openMasterModal('Add','')">
                  <em class="fa fa-plus" aria-hidden="true"></em>
                  <span>{{this.conditions.showButton.buttonName}}</span>
                </button>
              </ng-container>
              <ng-template #basicAdd>
                <button type="button" class="nav-link m-0 btn btn-primary listlink w-100"
                  [routerLink]="[this.conditions.showButton.routerLink]">
                  <em class="fa fa-plus" aria-hidden="true"></em>
                  <span>{{this.conditions.showButton.buttonName}}</span>
                </button>
              </ng-template>
            </ng-container>
            <!-- show button -->

            <!-- show grid / list button -->
            <button type="button" *ngIf="this.conditions?.showgridListButton && !showGridLayout"
              class="nav-link  btn btn-primary listlink" (click)="showGridLayout = true">
              <em class="fa fa-th-large" aria-hidden="true"></em>
              <span>Grid</span>
            </button>
            <button type="button" *ngIf="this.conditions?.showgridListButton && showGridLayout"
              class="nav-link  btn btn-primary listlink" (click)="showGridLayout = false">
              <em class="fa fa-bars" aria-hidden="true"></em>
              <span>List</span>
            </button>
            <!-- show grid / list button --> 
          
          </div>

          <!-- download CSV and EXCEL -->
          <ng-container *ngIf="this.conditions.showExport">          
          <div class="dropdown w-150">
            <button class="nav-link  btn btn-teal dropdown-toggle w-100" type="button" id="dropdownMenuButton"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
              [disabled]="!tableData?.data?.length">
              <span>Export</span>
            </button>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" (click)="CallAPIdownloadCSVandExcel('csv', isFilter=false, csvUrl)">Export to
                CSV</a>
              <a class="dropdown-item" (click)="CallAPIdownloadCSVandExcel('excel', isFilter=false, excelUrl)">
                Export to Excel</a>
            </div>
          </div>         
        </ng-container>
        <!-- download CSV and EXCEL -->

        </div>
      </div>
    </div>
    </div>
    <!-- TABLE HEADER END-->

    <!-- ON COLLAPSE FILTER -->
    <div class="collapse" [collapse]="!filterColumnOption" [isAnimated]="true" id="filterColumn">
      <div class="mt-3 filter-list">
        <div class="row">
          <ng-container *ngFor="let i of tableConfig?.cols">
            <div class="col-xl-3 col-lg-4 col-sm-6 col-12" *ngIf="i.filter">
              <div class="form-group">
                <label>{{i.colName}}</label>
                <ng-select
                  *ngIf="i.colName == 'Status' || i.colName == 'Published' || i.colName == 'Order Status' || i.colName == 'Refund Status'|| i.colName == 'Availability' || i.colName == 'Discount Type'"
                  class="ng-select-line" [items]="statusFilterlist" bindValue="value" bindLabel="status"
                  placeholder="Enter {{i.colName}}" [clearable]='false' [multiple]="i.colName == 'Status' || i.colName == 'Published' || i.colName == 'Availability' || i.colName == 'Discount Type' ? false : true" [dropdownPosition]="'auto'"
                  [closeOnSelect]="true" (focus)="getSatutsFilterlistData(i.colFieldname)"
                  (change)="selectFilterStatus(i.colFieldname,$event)" [(ngModel)]="i.value"
                  (keydown)="i.colName == 'E-mail' || i.colName == 'Buyer Email' || i.colName == 'Order ID' || i.colName == 'Return ID' || i.colName == 'Shipment ID' || i.colName == 'Transaction ID'  ? '' : (i.colName == 'Mobile' ? restrictKeyPressService.AllowNumbers($event) : restrictKeyPressService.AllowChar($event))"
                  required>
                </ng-select>

                <input type="text" [name]="i.colFieldname" class="form-control" [id]="i.colFieldname"
                  *ngIf="i.colName == 'Order Date' || i.colName == 'Shipment Date' || i.colName == 'Return Date' || i.colName == 'Validity'"
                  autocomplete="off" bsDaterangepicker [(ngModel)]="i.validity"
                  [bsConfig]="{ rangeInputFormat : 'MM/DD/YYYY',dateInputFormat: 'MM/DD/YYYY', showWeekNumbers: false, containerClass: 'theme-default' }"
                  placeholder="Select Dates" (bsValueChange)="dateCreated(i.colFieldname,$event)" />

                <input type="text" [name]="i.colFieldname" class="form-control" [id]="i.colFieldname"
                  *ngIf="i.colName == 'Validity Start' || i.colName == 'Validity End'"
                  autocomplete="off" bsDatepicker
                  [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', showWeekNumbers: false, containerClass: 'theme-default' }"
                  placeholder="Select Date" />

                  <ng-container *ngIf="i.colName == 'Discount'">
                    <div class="d-flex">
                      <input type="text" name="min" class="form-control" id="min"autocomplete="off" placeholder="Minimum" />
                      <input type="text" name="max" class="form-control ml-3" id="max"autocomplete="off" placeholder="Maximum" />
                    </div>
                  </ng-container>
                  

                <app-filter-type-ahead
                  [hidden]="i.colName === 'Status'|| i.colName === 'Published' || i.colName == 'Order Status' || i.colName == 'Return Date' || i.colName == 'Order Date' || i.colName == 'Shipment Date' || i.colName == 'Availability' || i.colName == 'Discount Type' || i.colName == 'Validity' || i.colName == 'Discount'"
                  [data]='i' [type]="this.type" #filterTypeAhead
                  (selectedValueOfFilter)="getSelectedFilterValueFromTyeAhead($event)">
                </app-filter-type-ahead>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="row">
          <div class="col-md-12" *ngIf="dataOfSaveFilter?.length">
            <h4>Saved Filters</h4>
            <div *ngFor="let i of dataOfSaveFilter" class="save-filter">
              <button type="button" class="btn btn-primary" (click)="showSaveFilter(i)">{{i.filterName}} <span
                  class="fa fa-close ml-2" aria-hidden="true" tooltip="Delete" placement="bottom"
                  (click)="CallAPIdeleteSaveFilter(i)"></span></button>
            </div>
          </div>
        </div>
        <hr>
        <div class="row filter-btn">
          <div class="col-md-12 text-right">
            <ng-container *ngIf="this.conditions.showSaveFilter">
              <button type="button" class="nav-link btn btn-primary" (click)="openSaveFilterModal(saveFilters)"
                [disabled]="disableApplyFilter">
                Save Filter
              </button>
            </ng-container>
            <button type="button" class="nav-link btn btn-primary" (click)="applyFilter()"
              [disabled]="disableApplyFilter">Apply
              Filter</button>
            <button type="button" class="nav-link btn btn-outline-primary" (click)="resetFilter()"
              [disabled]="disableResetFilter">Reset</button>
          </div>
        </div>
      </div>
    </div>
    <!-- ON COLLAPSE FILTER -->

    <!-- ON COLLAPSE COLUMN HIDE SHOW -->
    <div class="collapse" [collapse]="!columnVisibilityOptions" [isAnimated]="true" id="columnVisiblityFilter">
      <div class="item-list mt-3">
        <div class="row">
          <div *ngFor="let i of tableConfig?.cols" [ngClass]="(i.columnVisibility)?'col-md-3':''">
            <ng-container *ngIf="i.columnVisibility">
              <div class="checkbox">
                <label>
                  <input type="checkbox" name="status" [(ngModel)]="i.isVisible" (change)="changeCheckBoxValue()"
                    [disabled]="i.disable">
                  <span></span>
                  {{i.colName}}
                </label>
              </div>
            </ng-container>
          </div>
        </div>
        <hr />
        <div class="row text-right filter-btn">
          <div class="col-md-12">
            <button type="submit" class="nav-link btn btn-primary"
              (click)=" CallAPIchangeColumnVisibility(tableConfig?.cols)">Save</button>
            <button type="button" class="nav-link btn btn-primary" (click)="selectAllColumnVisible()">Select
              All</button>
            <button type="button" class="nav-link btn btn-outline-primary"
              (click)="resetColumnVisible()">Reset</button>
          </div>
        </div>
      </div>
    </div>
    <!-- ON COLLAPSE COLUMN HIDE SHOW -->


    <!-- List Layout -->
    <div class="table-responsive"
      [ngClass]="router.url.includes(URLConstants.PRODUCTS_DETAIL) || router.url.includes(URLConstants.DASHBOARD)  || router.url.includes(URLConstants.USERS_DETAIL) ? '' : 'card-body table-responsive pl-0 pr-0'"
      [hidden]="showGridLayout">
      <!-- Data Table Start -->
      <table aria-describedby="desc" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" sortable-table (sorted)="onSorted($event)"
        class="custom-table table table-striped" id="table" *ngIf="tableData?.data" >
        <thead>
          <tr>
            <!-- responsive button -->
            <th id="th"></th>
            <!-- responsive button -->
            <th id="1"[hidden]="!i.isVisible" [sortable-column]="i" [displaySortIcon]="i.sort"
              *ngFor="let i of tableConfig?.cols"
              [ngClass]="{'text-truncate': i.type == 'html', 'align-center':i.type == 'symbol' }">
              <ng-container *ngIf="i.type!=='multipleSelection' && i.type!=='switch'">
                {{i.colName}}
              </ng-container>
              <ng-container *ngIf="i.type=='switch'">{{i.colName}}</ng-container>
              <div *ngIf="i.type=='multipleSelection'" class="checkbox">
                <label>
                  <input type="checkbox" name="checkbox" [(ngModel)]="i.checkbox" (change)="selectAllUser(i)">
                  <span></span>
                </label>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let i of tableData?.data;let y=index;">
            <tr class="animated fadeIn">
              <td></td>
              <td [hidden]="!j.isVisible" *ngFor="let j of tableConfig?.cols" [ngClass]="j.colFieldname=='orderStatus'?'capitalize':''">
                <div *ngIf="j.type=='multipleSelection'" class="checkbox">
                  <ng-container *ngIf="type === 'promoCodeList';else basicMultipleSelection">
                    <label *ngIf="i['usedInOrders'].length == 0">
                      <input type="checkbox" name="checkbox" [(ngModel)]="i.checkbox"
                      (change)="selectMultipleUser(i)"><span></span>
                    </label>
                  </ng-container>
                  <ng-template #basicMultipleSelection>
                    <label><input type="checkbox" name="checkbox" [(ngModel)]="i.checkbox"
                      (change)="selectMultipleUser(i)"><span></span></label>
                  </ng-template>
                </div>

                <div *ngIf="j.type=='switch'">
                  <label class="switch ">
                    <input type="checkbox" class="success" [(ngModel)]="i[j.colFieldname]" (click)="changeStatus(i)" [disabled]="type === 'vendorProductList' ? true : false">
                    <span class="slider round"></span>
                  </label>
                </div>

                <div *ngIf="j.type=='image'" class="thumb-img">
                  <span>
                    <ng-container *ngIf="type == 'userlist' || type == 'adminUserlist' || type == 'vendorList' || type == 'driverListing';else noImageIcon">
                      <img [src]="i[j.colFieldname] ? uploadImageUrl + i[j.colFieldname] : 'assets/images/NoProfile.png'"
                      alt="no img" width="50px">
                    </ng-container>
                    <ng-template #noImageIcon>
                      <img [src]="i[j.colFieldname] ? uploadImageUrl + i[j.colFieldname] : 'assets/images/noImageAvailable.png'"
                      alt="no img" width="50px">
                    </ng-template>
                  </span>
                </div>

                <ng-container *ngIf="j.type=='action'">
                  <div class="d-flex flex-md-row align-items-center flex-column">
                    <div *ngFor="let k of tableConfig?.actions" class="action-icon">
                      <ng-container *ngIf="k.type === 'icon'">
                        <span class="cursor" >
                          <ng-container *ngIf="k.id === 3 && type === 'promoCodeList';else basicAction">
                            <em *ngIf="i['usedInOrders'].length == 0" [class]="k.class" aria-hidden="true" [tooltip]="k.tooltip" (click)="performAction(k,i)"></em>
                          </ng-container>
                          <ng-template #basicAction>
                              <em [class]="k.class" aria-hidden="true" [tooltip]="k.tooltip" (click)="performAction(k,i)"></em>   
                          </ng-template>
                        </span>
                      </ng-container>
                      <div *ngIf="k.type === 'button'">
                        <ng-container >
                          <button type="button" [class]="k.class" (click)="performAction(k,i)">{{k.buttonTitle}}</button>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </ng-container>

                <div *ngIf="j.type =='symbol'" class="align-center">
                  <span *ngIf="i[j.colFieldname] === true">&#10003;</span>
                  <span *ngIf="i[j.colFieldname] === false">&#9747;</span>
                </div>

                <div *ngIf="j.type=='emailVerified'">
                  <em [ngClass]="i[j.isEmailVerified] == true ? 'fa fa-check-circle' : 'fa fa-times-circle'"
                    aria-hidden="true"></em>
                  {{i[j.colFieldname] ? i[j.colFieldname] : '--'}}
                </div>

                <div *ngIf="j.type=='text'">
                  {{j.isArr ? (i[j.colFieldname][2] ? (i[j.colFieldname][0][j.isArr] + ', ' + i[j.colFieldname][1][j.isArr] + ', ' + i[j.colFieldname][2][j.isArr]) : (i[j.colFieldname][1] ? i[j.colFieldname][0][j.isArr] + ', ' + i[j.colFieldname][1][j.isArr] : i[j.colFieldname][0][j.isArr]) )  : (j.isObj && i[j.colFieldname] !== null && i[j.colFieldname] ? i[j.colFieldname][j.isObj] : (j.isDate ? (i[j.colFieldname] | date: globalSettings.dateFormat) :  j.isCurrency ? (i[j.colFieldname] | currency : globalSettings.currency) : (j.isDateObj && i[j.colFieldname] !== null && i[j.colFieldname]) ? (i[j.colFieldname][j.isDateObj] | date : globalSettings.dateFormat) : j.isCount ? (i[j.colFieldname].length) : i[j.colFieldname] ? i[j.colFieldname] : '--'))}}
                </div>

                <div *ngIf="j.type=='isIndexArr'">
                  <ng-container *ngIf="i[j.colFieldname].length;else indexEmp">
                    <ng-container *ngFor="let item of i[j.colFieldname];let isLast=last">
                      {{ item }}{{isLast ? '' : ', '}}
                    </ng-container>
                  </ng-container>
                  <ng-template #indexEmp>--</ng-template>
                </div>

                <div *ngIf="j.type=='mixed'">
                  <ng-container *ngIf="j.mixColumns.length;else empMix">
                    <ng-container *ngIf="type === 'productViseReviewList' || type === 'ProductReviewList' || type === 'VendorReviewList' || type === 'notificationList' || type === 'faqList';else basicCol">
                      <ng-container *ngIf="type === 'notificationList' || type === 'faqList';else productMixCol">{{ i[j.mixColumns[1]] }}<br/>( {{ type == 'notificationList' ? (i[j.mixColumns[0]] | date : globalSettings.dateFormat) : i[j.mixColumns[0]] }} )</ng-container>
                      <ng-template #productMixCol>{{ i[j.mixColumns[0]] }}<br/>( {{ i[j.mixColumns[1]] }} )</ng-template>
                    </ng-container>

                      <ng-template #basicCol>
                          <ng-container *ngFor="let item of j.mixColumns;let isLast=last">
                          {{ i[item] }}{{isLast ? '' : ' - '}}
                          </ng-container>
                      </ng-template>
                  </ng-container>
                  <ng-template #empMix>--</ng-template>
                </div>

                <div *ngIf="j.type=='validity'">
                  <ng-container *ngIf="j.mixColumns.length;else empVal">
                    {{ i[j.colFieldname][j.mixColumns[0]] | date  }} - {{ i[j.colFieldname][j.mixColumns[1]] | date }}
                    <!-- {{ i[j.colFieldname][j.mixColumns[0]] | date : globalSettings.dateFormat }} - {{ i[j.colFieldname][j.mixColumns[1]] | date : globalSettings.dateFormat }} -->
                  </ng-container>
                  <ng-template #empVal>--</ng-template>
                </div>
                
                <div *ngIf="j.type=='discount'">
                    {{  i['discountType'] === 'Percentage' ? i[j.colFieldname]+'%' : (i[j.colFieldname] | currency : globalSettings.currency ) }}
                </div>


                <div *ngIf="j.type=='rating'">
                  <rating name="rating" [(ngModel)]="i[j.colFieldname]" [max]="MAX_RATING" [readonly]="true"></rating>
                </div>

                <div *ngIf="j.type=='text_route'">
                  <a [routerLink]="[j.routerLink,i._id]" class="text-route-color">{{i[j.colFieldname]}}</a>
                </div>

                <div *ngIf="j.type=='isOnline'">
                  <div [ngClass]="i[j.colFieldname] ? 'text-success' : 'text-danger' ">
                    {{i[j.colFieldname] ?  'Online' : 'Offline'}}</div>
                </div>
                <div *ngIf="j.type=='Comment' && i[j.colFieldname].length<=50">
                    {{i[j.colFieldname]}}
                </div>
                <div *ngIf="j.type=='Comment' && i[j.colFieldname].length>50">
                  <span [tooltip]="i[j.colFieldname]"
                  placement="top">{{ i[j.colFieldname]|slice:0:50}}....</span>
                </div>

                <div *ngIf="j.type=='html'" [innerHTML]="i[j.colFieldname] | safe: 'html' "></div>

              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
      <!-- Data Table End -->
    </div>
    <!-- List Layout -->

    <!-- Gird Layout -->
    <div [hidden]="!showGridLayout">
      <div *ngIf="!tableData?.data?.length" class="text-center p-4">No data available.</div>
      <div class="row" *ngIf="tableData?.data?.length">
        <div class="col-md-4 product-item col-lg-3" *ngFor="let i of tableData?.data;let y=index;">
          <div class="thumbnail">
            <div class="thumbnail-wrapper"><a></a>
              <div class="checkbox">
                <label>
                  <input type="checkbox" name="checkbox" [(ngModel)]="i.checkbox" class="form-check-input"
                    (change)="selectMultipleUser(i,y)"><span></span>
                </label>
              </div>
              <div class="product-setting">
                <div *ngFor="let k of tableConfig?.actions">
                  <label class="text-left" *ngIf="k.id == 2"><span><em (click)="performAction(k,i)"
                        class="fa fa-pencil"></em></span></label>
                  <label class="text-left" *ngIf="k.id === 3"><span><em (click)="performAction(k,i)"
                        class="fa fa-trash"></em></span></label>
                  <label class="text-left" *ngIf="k.id === 4"><span><em (click)="performAction(k,i)"
                        class="fa fa-wallet"></em></span></label>
                </div>
              </div>
              <div class="image-wrapper"
                [routerLink]="router.url.includes(URLConstants.CATEGORY) ? [URLConstants.SUB_CATEGORY,i._id] : [URLConstants.SUB_CATEGORY,i._id]">
                <img [src]="i.image ? uploadImageUrl + i.image : 'assets/images/noImageAvailable.png'" alt="No img">
                <p>{{i.categoryName}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Gird Layout -->

    <!-- TABLE FOOTER START-->
    <ng-container *ngIf="this.conditions.showTableFooter && tableData?.data?.length">
      <div class="card-footer pl-0 pr-0">
        <div class="custom-pagination  justify-content-end">
          <span class="record-count">Showing</span>
          <ng-select class="ng-select-line" (change)="selectItemPerPage(itemsPerPage)" [items]="itemsPerPageList"
            placeholder="Select" [(ngModel)]="itemsPerPage" [clearable]='false' [searchable]='false'
            [dropdownPosition]="'auto'" [labelForId]="tableData.type">
          </ng-select>
          <span class="record-count">out of {{total}} {{total>1?'Entries':'Entry'}}</span>
        </div>
        <div class="pagination-list">
          <pagination [itemsPerPage]='itemsPerPage' #customPagination [totalItems]="total"
            (pageChanged)="pagination($event)" [maxSize]="5" previousText="Prev" nextText="Next" firstText="&laquo;"
            lastText="&raquo;">
          </pagination>
        </div>
      </div>
    </ng-container>
    <!-- TABLE FOOTER END-->
  </div>
</div>


<!-- SAVE FILTER MODAL -->
<ng-template #saveFilters>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Save Filter</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="BsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form #saveFilterForm="ngForm" (ngSubmit)="submitSaveFilter(saveFilterForm, filterToSave)">
      <div class="form-group" [ngClass]="((submitted || filterName.touched) && filterName.invalid)?'has-danger':''">
        <input type="text" class="form-control" placeholder="Enter Name Of Filter" name="filterName"
          #filterName="ngModel" [(ngModel)]="filterToSave.filterName" autocomplete="off" required>
        <div class="error-block text-left" *ngIf="((submitted || filterName.touched) && filterName.invalid)">
          {{showErrorService.showError('filterName', filterName.errors)}}
        </div>
      </div>
      <div class="text-right">
        <button type="submit" class="btn btn-primary">Save</button>
      </div>
    </form>
  </div>
</ng-template>
<!-- SAVE FILTER MODAL -->